import React, { createContext, useContext } from "react";
import { useRefreshKey } from "../../../components/RefreshProvider/refreshProvider";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";

export const PracticeDataContext = createContext();

export const usePracticeData = () => useContext(PracticeDataContext);

const PracticeDataProvider = ({ children }) => {
    const { refreshKey } = useRefreshKey();
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/practices/active?refreshKey=${refreshKey}`
    );
    return (
        <PracticeDataContext.Provider
            value={{
                practiceData: response?.data,
                loading: loading,
                error: error,
            }}
        >
            {children}
        </PracticeDataContext.Provider>
    );
};

export default PracticeDataProvider;
