import React, { useState } from "react";
import "./feedback.css";
import PageLayout from "../../../components/pageLayout";
import Field from "../../../components/Field/field";
import { useNavigate } from "react-router-dom";
import fetchWithHeaders from "../../../components/fetchWithHeaders";

const Feedback = () => {
    const [submitted, setSubmitted] = useState(false);
    const [formPage, setFormPage] = useState(1); 
    const [formData, setFormData] = useState({
        frequency: "",
        usage: "",
        favoriteFeature: "",
        timeTracking: "",
        sessionRecording: "",
        dataSharing: "",
        metronomeAndTuner: "",
        appEaseOfUse: "",
        mostLiked: "",
        leastLiked: "",
        additionalComments: "",
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleNext = () => {
        const inputFields = Array.from(
            document.querySelectorAll("input, select, textarea")
        );

        let allFieldsFilled = true;
        inputFields.forEach((field) => {
            if (field.required && field.value.trim() === "") {
                field.focus();
                field.reportValidity();
                allFieldsFilled = false;
            }
        });

        if (!allFieldsFilled) {
            return;
        }

        setFormPage((prevPage) => prevPage + 1);
    };

    const handleBack = () => {
        setFormPage((prevPage) => prevPage - 1); 
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchWithHeaders("POST", "/feedback-email", formData)
            .then(({ status, data, error }) => {
                if (error) throw new Error(error);
                setSubmitted(true);
            })
            .catch((error) => {
                console.log(error);
                alert("There was an error submitting the form");
            });
    };

    function renderOptions() {
        return (
            <React.Fragment>
                <option value="" disabled>
                    Select satisfaction level
                </option>
                <option value="Very satisfied">Very satisfied</option>
                <option value="Satisfied">Satisfied</option>
                <option value="Neutral">Neutral</option>
                <option value="Dissatisfied">Dissatisfied</option>
                <option value="Very dissatisfied">Very dissatisfied</option>
            </React.Fragment>
        );
    }

    if (submitted) {
        return (
            <PageLayout disableSideComponent>
                <div className="submission-message">
                    <h1>Thank you for your feedback!</h1>
                    <h4>
                        Thank you for your helpful feedback, it helps us keep
                        our product great! We will be sending you a review of
                        your answers via email.
                    </h4>
                    <button
                        onClick={() => navigate("/dashboard")}
                    >Back To Dashboard</button>
                </div>
            </PageLayout>
        );
    }

    return (
        <PageLayout disableSideComponent>
            <div className="feedback-content">
                <h1>PracticePro</h1>
                <h2>Feedback</h2>
                <p className="intro-text">
                    Thank you for using the Practice Pro app! Your feedback is
                    invaluable in helping us improve the app and better serve
                    your needs. Please take a few minutes to complete this
                    survey.
                </p>
                <form onSubmit={handleSubmit} className="feedback-form">
                    {formPage === 1 && (
                        <div className="form-page">
                            <label>How often do you use PracticePro?</label>
                            <select
                                name="frequency"
                                value={formData.frequency}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Select frequency
                                </option>
                                <option value="Daily">Daily</option>
                                <option value="A few times a week">
                                    A few times a week
                                </option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Rarely">Rarely</option>
                                <option value="Other">Other</option>
                            </select>
                            <Field
                                name="usage"
                                value={formData.usage}
                                onChange={handleChange}
                                label="How do you mainly use PracticePro?"
                                textarea
                                required
                            />
                            <Field
                                name="favoriteFeature"
                                value={formData.favoriteFeature}
                                onChange={handleChange}
                                label="What feature do you like the most?"
                                textarea
                                required
                            />
                            <div className="button-group">
                                <button
                                    type="submit"
                                    className="next-button"
                                    onClick={handleNext}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}

                    {formPage === 2 && (
                        <div className="form-page">
                            <label>
                                How satisfied are you with the time tracking?
                            </label>
                            <select
                                name="timeTracking"
                                value={formData.timeTracking}
                                onChange={handleChange}
                                required
                            >
                                {renderOptions()}
                            </select>

                            <label>
                                How satisfied are you with the session
                                recording?
                            </label>
                            <select
                                name="sessionRecording"
                                value={formData.sessionRecording}
                                onChange={handleChange}
                                required
                            >
                                {renderOptions()}
                            </select>

                            <label>
                                How satisfied are you with the student/teacher
                                data sharing?
                            </label>
                            <select
                                name="dataSharing"
                                value={formData.dataSharing}
                                onChange={handleChange}
                                required
                            >
                                {renderOptions()}
                            </select>

                            <label>
                                How satisfied are you with the metronome and
                                tuner?
                            </label>
                            <select
                                name="metronomeAndTuner"
                                value={formData.metronomeAndTuner}
                                onChange={handleChange}
                                required
                            >
                                {renderOptions()}
                            </select>

                            <label>How easy is the app to use?</label>
                            <select
                                name="appEaseOfUse"
                                value={formData.appEaseOfUse}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Select ease level
                                </option>
                                <option value="Very easy">Very easy</option>
                                <option value="Easy">Easy</option>
                                <option value="Neutral">Neutral</option>
                                <option value="Difficult">Difficult</option>
                                <option value="Very Difficult">
                                    Very Difficult
                                </option>
                            </select>

                            <div className="button-group">
                                <button
                                    type="button"
                                    className="back-button"
                                    onClick={handleBack}
                                >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    className="next-button"
                                    onClick={handleNext}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}

                    {formPage === 3 && (
                        <div className="form-page">
                            <Field
                                name="mostLiked"
                                value={formData.mostLiked}
                                onChange={handleChange}
                                label="What do you like most about the app?"
                                textarea
                                required
                            />
                            <Field
                                name="leastLiked"
                                value={formData.leastLiked}
                                onChange={handleChange}
                                label="What do you like least about the app?"
                                textarea
                                required
                            />
                            <Field
                                name="additionalComments"
                                value={formData.additionalComments}
                                onChange={handleChange}
                                label="Additional comments or suggestions"
                                textarea
                            />

                            <div className="button-group">
                                <button
                                    type="button"
                                    className="back-button"
                                    onClick={handleBack}
                                >
                                    Back
                                </button>
                                <button type="submit" className="submit-button">
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </PageLayout>
    );
};

export default Feedback;
