import PageLayout from "../../../components/pageLayout";
import PieceDetails from "../../../components/Piece/pieceDetails";
import Logo from "../../../components/Logos/logo";
import PieceSubscriptionDetails from "./pieceSubscriptionDetails";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import Table from "../../../components/Table/table";
import Popup from "../../../components/Popup/popup";
import StudentOptions from "../../../components/Student/studentOptions";
import AddPieceComponent from "../../../components/Piece/addPiecePopup";
import DifficultyOptions from "../../../components/Assignment/difficultyOptions";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import { formatDate, formatSize } from "../../../lib/format";
import { useSearchParams } from "react-router-dom";
import { useAlert } from "../../../components/AlertBanner/alertProvider";
import { useRefreshKey } from "../../../components/RefreshProvider/refreshProvider";
import AssignPiecesComponent from "../../../components/Piece/assignPiecesComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ICON_MAPPING } from "../../../lib/iconMapping"

const PiecesPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [pieceSelected, setPieceSelected] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [pieces, setPieces] = useState([]);
    const [isAssigning, setIsAssigning] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [studentsToAssign, setStudentsToAssign] = useState([]);
    const [difficulty, setDifficulty] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [piecesSelected, setPiecesSelected] = useState([]);
    const [tags, setTags] = useState("");
    const { showAlert } = useAlert();
    const { refreshKey, refresh } = useRefreshKey();

    function checkIfUnassigned(num) {
        if (num === 0) {
            return <div className="invalid">Not Assigned</div>;
        }
        return num;
    }

    const headCells = [
        {
            id: "name",
            numeric: false,
            label: "Name",
        },
        {
            id: "composer",
            numeric: false,
            label: "Composer",
        },
        {
            id: "arranger",
            numeric: false,
            label: "Arranger",
            responsive: true,
        },
        {
            id: "assignedCount",
            numeric: true,
            label: "Assignments",
            formatter: checkIfUnassigned,
            responsive: true,
        },
        {
            id: "size",
            numeric: true,
            label: "Size",
            formatter: formatSize,
            responsive: true,
        },
        {
            id: "createdAt",
            numeric: false,
            label: "Date Created",
            formatter: formatDate,
            responsive: true,
        },
        {
            id: "tags",
            numeric: false,
            label: "Tags",
            formatter: (tags) => {
                return (
                    <div className="table-tags-container">
                        {tags?.map((tag, i) => {
                            if (i > 3) {
                                return;
                            }
                            return (
                                <div key={i} className="tag">
                                    {tag}
                                </div>
                            );
                        })}
                    </div>
                );
            },
            responsive: true,
        },
    ];

    function addPiece(selected) {
        setIsAdding(true);
    }

    async function archivePieces() {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "PATCH",
                "/pieces",
                { pieces: piecesSelected }
            );

            if (error) throw new Error(error);
            showAlert("Successfully archived pieces", "success");
            refresh();
            setIsArchiving(false);
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error archiving the pieces: " + error,
                "error"
            );
        }
    }

    function handleIsArchiving(selected) {
        setIsArchiving(true);
    }

    function handleIsAssigning(selected) {
        setIsAssigning(true);
    }

    const bulkActions = [
        {
            label: "Add",
            action: addPiece,
            requiresSelected: false,
            isActive: true,
        },
        {
            label: "Assign",
            action: handleIsAssigning,
            requiresSelected: true,
            isActive: true,
        },
        {
            label: "Archive",
            action: handleIsArchiving,
            requiresSelected: true,
            isActive: true,
        },
    ];

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/pieces?tags=${tags}&refreshKey=${refreshKey}`
    );

    useEffect(() => {
        if (response) {
            setPieces(response.data);
        }
    }, [response]);

    function handleDetails(pieceId) {
        setPieceSelected(pieceId);
        setSearchParams(`pieceId=${pieceId}`);
        setIsOpen(!isOpen);
    }

    return (
        <PageLayout
            isOpen={isOpen && pieceSelected}
            setIsOpen={setIsOpen}
            sideComponent={
                pieceSelected && (
                    <PermissionsComponent permissions={[Permission.READ_PIECE]}>
                        <PieceDetails id={pieceSelected} />
                    </PermissionsComponent>
                )
            }
        >
            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <FontAwesomeIcon
                    icon={ICON_MAPPING["piece"]}
                    color="var(--accent)"
                    fontSize={"large"}
                />
                <h1 style={{ marginBottom: "0" }}>Pieces</h1>
            </div>
            <PermissionsComponent
                permissions={[
                    Permission.READ_SUBSCRIPTION,
                    Permission.WRITE_SUBSCRIPTION,
                ]}
            >
                <PieceSubscriptionDetails />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.READ_PIECE]}>
                <Table
                    headCells={headCells}
                    orderByDefault={headCells[0].id}
                    rows={pieces}
                    onFilterTags={setTags}
                    bulkActions={bulkActions}
                    emptyTableMessage={"No Pieces"}
                    handleClick={handleDetails}
                    onSelect={(selected) => setPiecesSelected(selected)}
                />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.WRITE_PIECE]}>
                <AddPieceComponent state={isAdding} setState={setIsAdding} />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.WRITE_ASSIGNMENT]}>
                <AssignPiecesComponent
                    pieces={piecesSelected}
                    state={isAssigning}
                    setState={setIsAssigning}
                />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.DELETE_PIECE]}>
                <Popup open={isArchiving} setIsOpen={setIsArchiving}>
                    <h2>
                        Are you sure you want to archive {piecesSelected.length}{" "}
                        piece{piecesSelected.length > 1 ? "s" : ""}?
                    </h2>{" "}
                    <p className="invalid">
                        Warning: All assignments for this piece will be deleted!
                    </p>
                    <button onClick={archivePieces}>Yes, Archive</button>
                </Popup>
            </PermissionsComponent>
        </PageLayout>
    );
};

export default PiecesPage;
