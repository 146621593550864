import React, { useContext, useEffect, useState } from "react";
import LogoWithText from "../../../components/Logos/logoWithText";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import { Navigate, useNavigate } from "react-router-dom";
import StudentComponent from "../../../components/studentComponent";
import { UserContext } from "../../../components/userProvider";
import { jwtDecode } from "jwt-decode";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";

const EndedMembership = () => {
    const { user, loading, error } = useContext(UserContext);
    const navigate = useNavigate();

    if (error) {
        return <Navigate to="/login" />;
    }

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    const decoded = jwtDecode(localStorage.getItem("auth-token"));

    if (decoded.subscriptionIsValid) {
        return <Navigate to={"/login"} />;
    }

    const handleCreatePortalSession = async () => {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/stripe/create-portal-session"
            );

            if (data.redirect_url) {
                localStorage.clear();
                window.location.href = data.redirect_url;
            } else {
                console.error("Error: No redirect URL found in response");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate("/login");
    };

    return (
        <div className="page centered">
            <div className="paper">
                <LogoWithText width={150} />
                <h1>Hi, {user.fname}</h1>

                <h2>Your membership has expired</h2>
                <StudentComponent>
                    <p>
                        Contact Your Teacher or Administrator to unlock your
                        account
                    </p>
                </StudentComponent>
                <div className="button-group">
                    <PermissionsComponent
                        permissions={[Permission.WRITE_SUBSCRIPTION]}
                    >
                        <button onClick={handleCreatePortalSession}>
                            Manage my membership
                        </button>
                    </PermissionsComponent>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </div>
    );
};

export default EndedMembership;
