import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faTag, faUser } from "@fortawesome/free-solid-svg-icons";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import { ICON_MAPPING } from "../../../lib/iconMapping";

const UserDetails = ({ id }) => {
    let { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/users/${id}`
    );
    const navigate = useNavigate();
    const [user, setUser] = useState({});

    useEffect(() => {
        if (response) {
            setUser(response.data);
        }
    }, [response]);

    if (error) {
        return (
            <div className="paper">
                <p className="invalid">
                    There was an error loading this content
                </p>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }
    return (
        <div>
            <h2>
                <FontAwesomeIcon
                    color="var(--accent)"
                    icon={ICON_MAPPING[user?.userType] || faUser}
                    style={{ marginRight: "0.5rem" }}
                />
                {user?.fname + " " + user?.lname}
            </h2>
            <h3>{user?.email}</h3>
            <section>
                <h4>
                    <FontAwesomeIcon
                        style={{
                            color: "var(--accent)",
                            marginRight: "0.5rem",
                        }}
                        icon={faTag}
                    />
                    Tags:
                    {user?.tags?.length <= 0 && " None"}
                </h4>
                <div className="tags-list">
                    {user?.tags?.map((tag, i) => (
                        <div className="tag" key={i}>
                            {tag}
                        </div>
                    ))}
                </div>
            </section>
            {user?.userType === "student" && (
                <React.Fragment>
                    <PermissionsComponent
                        permissions={[Permission.READ_TEACHER]}
                    >
                        <section>
                            <div>
                                <h4>
                                    <FontAwesomeIcon
                                        color="var(--accent)"
                                        style={{
                                            marginRight: "0.5rem",
                                        }}
                                        icon={ICON_MAPPING["teacher"]}
                                    />
                                    Teachers
                                </h4>
                                <div className="tags-list">
                                    {user?.teachers?.map(({ id, name }) => {
                                        return (
                                            <div className="tag" key={id}>
                                                {name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </PermissionsComponent>
                    <PermissionsComponent
                        permissions={[Permission.READ_ASSIGNMENT]}
                    >
                        <section>
                            <AssignmentsQuickView id={id} />
                        </section>
                    </PermissionsComponent>
                </React.Fragment>
            )}
            <div className="button-group">
                <button onClick={() => navigate(`/dashboard/users/${id}`)}>
                    More Details
                </button>
            </div>
        </div>
    );
};

const AssignmentsQuickView = ({ id }) => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/assignments?studentId=${id}`
    );

    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        if (response) {
            setAssignments(response.data);
        }
    }, [response]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h4>
                <FontAwesomeIcon
                    color="var(--accent)"
                    style={{
                        marginRight: "0.5rem",
                    }}
                    icon={faClipboard}
                />
                Assignments:{" "}
                {assignments?.length > 0 ? (
                    assignments?.length
                ) : (
                    <div className="invalid">No Assignments</div>
                )}
            </h4>
        </div>
    );
};

export default UserDetails;
