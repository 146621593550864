import React from "react";

import "./progressbar.css";

const ProgressBar = ({ progress, totalAmt }) => {
    function calculateProgress() {
        const toReturn = (90 * progress) / totalAmt;
        return toReturn + "%";
    }
    return (
        <div className="bar-container">
            <div className="bar" style={{ width: calculateProgress() }} />
        </div>
    );
};

export default ProgressBar;
