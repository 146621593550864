import React from "react";

import "./switch.css";

const Switch = ({ leftOption, rightOption, checked, onChange, disabled }) => {
    return (
        <div className="switch-container">
            {leftOption && <h3>{leftOption}</h3>}
            <label className="switch">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className="slider round"></span>
            </label>
            <h3>{rightOption}</h3>
        </div>
    );
};

export default Switch;
