import React from "react";
import "./listItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const ListItem = ({
    title,
    description,
    addDescription,
    onClick,
    message,
    color = null,
    image = null,
    empty = false,
}) => {
    return !empty ? (
        <React.Fragment>
            <li
                className="item"
                onClick={onClick}
                style={{ backgroundColor: color ? color : "" }}
            >
                {image && <img src={image} width={"50px"} />}
                <div className="item-description">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    {addDescription && <p>{addDescription}</p>}
                </div>
                <div className="arrow">
                    <FontAwesomeIcon icon={faCaretRight} className="icon" />
                </div>
            </li>
        </React.Fragment>
    ) : (
        <div className="item empty" onClick={onClick}>
            <FontAwesomeIcon icon={faAdd} className="icon" />
            <h4>{message || "Add New"}</h4>
        </div>
    );
};

export default ListItem;
