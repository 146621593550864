import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import LogoWithText from "../../components/Logos/logoWithText";
import Field from "../../components/Field/field";

const CheckoutSuccess = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sessionId, setSessionId] = useState(searchParams.get("sessionId"));
    const navigate = useNavigate();

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/stripe/checkout-session/${sessionId}`
    );

    if (error) {
        return navigate("/not-found");
    }

    if (loading) {
        return (
            <div className="page centered">
                <div className="loading-paper">
                    <div className="loading-anim"></div>
                    <LogoWithText width={150} />
                </div>
            </div>
        );
    }

    return (
        <div className="page centered">
            <div className="paper">
                <LogoWithText width="150px" />
                <div>
                    <h2>Success! Thank you for choosing Practice Pro!</h2>
                    <p>
                        We've emailed you a one time password to log in to your
                        account. You can change it in the settings page once
                        you've logged in
                    </p>
                    <button onClick={() => navigate(`/login`)}>
                        Login Here
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutSuccess;
