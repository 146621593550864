import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../components/userProvider";
import PageLayout from "../../../components/pageLayout";
import Field from "../../../components/Field/field";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import Popup from "../../../components/Popup/popup";

import "./settings.css";
import { formatSize } from "../../../lib/format";
import ProgressCircle from "../../../components/ProgressCircle/progressCircle";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import { useAlert } from "../../../components/AlertBanner/alertProvider";
import { useRefreshKey } from "../../../components/RefreshProvider/refreshProvider";

const formReducer = (state, event) => {
    if (event.reset) {
        return {};
    }

    return {
        ...state,
        [event.name]: event.value,
    };
};

const Settings = () => {
    const navigate = useNavigate();
    const { user, loading } = useProfile();
    const [hasChanged, setHasChanged] = useState(false);
    const [formData, setFormData] = useReducer(formReducer, {});
    const { showAlert } = useAlert();
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const { refreshKey, refresh } = useRefreshKey();

    useEffect(() => {
        if (user) {
            Object.entries(user).forEach(([key, value]) => {
                setFormData({ name: key, value: value });
            });
        }
    }, [user]);

    const handleChange = (event) => {
        if (!hasChanged) {
            setHasChanged(true);
        }
        setFormData({ name: event.target.name, value: event.target.value });
    };

    const resetForm = (event) => {
        Object.entries(user).forEach(([key, value]) => {
            setFormData({ name: key, value: value });
        });
        setHasChanged(false);
    };

    const saveChanges = async (event) => {
        event.preventDefault();

        if (formData.password && formData.password !== formData.confirm) {
            showAlert("Passwords do not match", "error");
            return;
        }
        if (!hasChanged) {
            return;
        }

        try {
            const { status, data, error } = await fetchWithHeaders(
                "PATCH",
                `/profile`,
                formData
            );

            if (error) throw new Error(error);
            showAlert("Account successfully updated", "success");
            setFormData({ reset: true });
            refresh();
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error updating your account: " + error,
                "error"
            );
        }
    };

    const handleCreatePortalSession = async () => {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/stripe/create-portal-session"
            );

            if (data.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                throw new Error("No redirect URL found in response");
            }
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error openning the portal: " + error,
                "error"
            );
        }
    };

    const handleSendVerifyEmail = async () => {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/auth/send-verification-email"
            );

            if (error) throw new Error(error);
            showAlert(
                "Your email has been sent! It may take a moment to reach your inbox",
                "success"
            );
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error sending the email: " + error,
                "error"
            );
        }
    };

    const confirmLogout = () => {
        localStorage.clear();
        navigate("/login");
    };

    return (
        <PageLayout loading={loading} disableSideComponent>
            <h3>PracticePro</h3>
            <h2>Settings</h2>
            <section>
                <h3>Profile</h3>
                <form onSubmit={saveChanges}>
                    <div className="form-fields">
                        <Field
                            name={"fname"}
                            label={"First name"}
                            type="fname"
                            onChange={handleChange}
                            placeholder={"First name"}
                            value={formData.fname}
                            required
                        />
                        <Field
                            name={"lname"}
                            label={"Last name"}
                            type="lname"
                            onChange={handleChange}
                            placeholder={"Last name"}
                            value={formData.lname}
                            required
                        />
                        <Field
                            name={"email"}
                            label={"Email"}
                            type="email"
                            onChange={handleChange}
                            placeholder={"Email"}
                            value={formData.email}
                            disabled
                        />
                        <Field
                            name={"password"}
                            label={"New password"}
                            value={formData.password}
                            type="password"
                            onChange={handleChange}
                        />
                        <Field
                            name={"confirm"}
                            label={"Confirm Password"}
                            value={formData.confirm}
                            onChange={handleChange}
                            type="password"
                            disabled={
                                !formData.password ||
                                formData?.password?.length <= 0
                            }
                        />
                    </div>
                    <div className="button-group">
                        <button type="submit" disabled={!hasChanged}>
                            Save Changes
                        </button>
                        <button
                            type="button"
                            onClick={resetForm}
                            disabled={!hasChanged}
                        >
                            Cancel Changes
                        </button>
                    </div>
                </form>
            </section>
            <PermissionsComponent permissions={[Permission.WRITE_SUBSCRIPTION]}>
                <section>
                    <h3>Membership</h3>
                    <SubscriptionDetails />
                    <button onClick={handleCreatePortalSession}>
                        View/Change Membership Settings
                    </button>
                </section>
            </PermissionsComponent>
            <section>
                <h3>App</h3>
                <div className="button-group">
                    <button
                        onClick={handleSendVerifyEmail}
                        disabled={user?.emailVerified}
                    >
                        {user?.emailVerified
                            ? "Email Verified"
                            : "Send Verification Email"}
                    </button>
                </div>
                <button
                    type="button"
                    style={{ backgroundColor: "var(--primary)" }}
                    className="invalid"
                    onClick={() => setShowLogoutConfirm(true)}
                >
                    Logout
                </button>
            </section>
            <Popup open={showLogoutConfirm} setIsOpen={setShowLogoutConfirm}>
                <h2>Confirm Logout</h2>
                <p>Are you sure you want to log out?</p>
                <div className="invalid">
                    <button
                        style={{
                            backgroundColor: "var(--primary)",
                            color: "red",
                        }}
                        onClick={confirmLogout}
                    >
                        Logout
                    </button>
                </div>
            </Popup>
        </PageLayout>
    );
};

const SubscriptionDetails = () => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/subscriptions"
    );
    const [subscriptionDetails, setSubscriptionDetails] = useState({});

    useEffect(() => {
        if (response) {
            setSubscriptionDetails(response.data);
        }
    }, [response]);

    return (
        <div className="section">
            <div className="sub-details-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        margin: "1rem 0",
                        gap: "1rem",
                    }}
                >
                    <ProgressCircle
                        progress={subscriptionDetails.currentStorageUsed || 0}
                        totalAmt={subscriptionDetails.pieceLimitBytes || 1}
                    />
                    <div>
                        <h1
                            style={{
                                fontSize: "40px",
                                color: "var(--secondary)",
                            }}
                        >
                            {formatSize(
                                subscriptionDetails.currentStorageUsed
                            ) || "-"}
                        </h1>
                        <h2>Storage Used</h2>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        margin: "1rem 0",
                        gap: "1rem",
                    }}
                >
                    <ProgressCircle
                        progress={subscriptionDetails.studentCount || 0}
                        totalAmt={subscriptionDetails.studentLimit || 1}
                    />
                    <div>
                        <h1
                            style={{
                                fontSize: "40px",
                                color: "var(--secondary)",
                            }}
                        >
                            {subscriptionDetails.studentCount || "0"}/
                            {subscriptionDetails.studentLimit || "-"}
                        </h1>
                        <h2>Students</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
