async function fetchWithHeaders(verb, url, data = null, blob = false) {
    "use client";
    try {
        var request = {
            method: verb,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("auth-token"),
            },
        };

        if (data) {
            request.body = JSON.stringify(data);
        }

        const res = await fetch(process.env.REACT_APP_API_URL + url, request);
        if (!res.ok) {
            throw new Error(await res.json());
        }
        return {
            status: res.status,
            data: blob ? await res.blob() : await res.json(),
        };
    } catch (error) {
        console.log(error);
        return { error: error.message, status: error.status };
    }
}

export default fetchWithHeaders;
