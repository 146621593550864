import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="page centered">
            <h1>Uh Oh!</h1>
            <h2>Sorry, we couldn't find the page you are looking for</h2>
            <button onClick={() => navigate("/dashboard")}>
                Take Me Back!
            </button>
        </div>
    );
};

export default NotFound;
