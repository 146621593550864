import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import fetchWithHeaders from "../../components/fetchWithHeaders";
import LogoWithText from "../../components/Logos/logoWithText";
import Field from "../../components/Field/field";

const ForgotPass = () => {
    const [email, setEmail] = useState("");
    const [complete, setComplete] = useState(false);
    const navigate = useNavigate();

    async function handleResetPassword(event) {
        event.preventDefault();
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                `/auth/forgot-password`,
                { email }
            );

            if (error) {
                throw new Error(error);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setComplete(true);
        }
    }

    return (
        <div className="page centered userSelection">
            <div className="paper">
                <LogoWithText width="150px" />
                {complete ? (
                    <div>
                        <h2>Your Password Has Been Reset</h2>
                        <p>
                            If your email matches an account, we'll send you a
                            password reset email
                        </p>
                        <button onClick={() => navigate(`/login`)}>
                            Back To Login
                        </button>
                    </div>
                ) : (
                    <div className="login-container">
                        <h2>Password Reset</h2>
                        <form onSubmit={handleResetPassword}>
                            <Field
                                name="email"
                                label="Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                type="email"
                                required
                            />
                            <button type="submit">Reset Password</button>
                        </form>
                        <button
                            className="back"
                            onClick={() => {
                                navigate(`/login`);
                            }}
                        >
                            <FontAwesomeIcon icon={faCaretLeft} />
                        </button>
                        <p>
                            <a href={`/app/login`}>Back to Login</a>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPass;
