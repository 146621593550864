import React from "react";

const Field = ({
    id,
    name,
    label,
    value,
    onChange,
    type = "text",
    textarea = false,
    rows = 4,
    disabled = false,
    required = false,
    min = null,
    max = null,
}) => {
    return (
        <label>
            <div>{label}</div>
            {textarea ? (
                <textarea
                    id={id || ""}
                    name={name}
                    value={value || ""}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    rows={rows}
                />
            ) : (
                <input
                    id={id || ""}
                    name={name}
                    value={value || ""}
                    type={type}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    min={min}
                    max={max}
                />
            )}
        </label>
    );
};

export default Field;
