import React from "react";

import "./progressCircle.css";

const ProgressCircle = ({ progress, totalAmt }) => {
    function calculateProgress() {
        if (progress < 0) return 0;
        const toReturn = (360 * progress) / totalAmt;
        return toReturn;
    }
    return (
        <div className="progress-circle-container">
            <div
                className="progress-circle"
                style={{
                    background: `conic-gradient(
       var(--accent) ${calculateProgress()}deg, var(--shadow) ${calculateProgress()}deg 360deg, var(--shadow) 360deg)`,
                }}
            />
        </div>
    );
};

export default ProgressCircle;
