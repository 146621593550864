import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import ProgressBar from "../../../components/ProgressBar/progressBar";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import { formatSize } from "../../../lib/format";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import HelpSection from "../../../components/HelpSection/helpSection";

const PieceSubscriptionDetails = () => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/subscriptions"
    );
    const [subscriptionDetails, setSubscriptionDetails] = useState({});

    useEffect(() => {
        if (response) {
            setSubscriptionDetails(response.data);
        }
    }, [response]);

    const handleCreatePortalSession = async () => {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/stripe/create-portal-session"
            );

            if (data.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                console.error("Error: No redirect URL found in response");
            }
        } catch (error) {
            console.log(error);
        }
    };

    if (error) {
        return (
            <div className="paper">
                <p className="invalid">
                    There was an error loading this content
                </p>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    return (
        <div className="section">
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <div>
                    <h1 style={{ fontSize: "40px", color: "var(--secondary)" }}>
                        {formatSize(subscriptionDetails.currentStorageUsed) ||
                            "-"}
                    </h1>
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                        <h2>Storage Used</h2>
                        <HelpSection
                            title="Membership"
                            message={`Your membership includes ${subscriptionDetails.pieceLimit} GB of storage, upgrade for more!`}
                        />
                    </div>
                </div>
                <PermissionsComponent
                    permissions={[Permission.WRITE_SUBSCRIPTION]}
                >
                    <div>
                        <button onClick={handleCreatePortalSession}>
                            See available plans
                        </button>
                    </div>
                </PermissionsComponent>
            </div>
            <ProgressBar
                progress={subscriptionDetails.currentStorageUsed || 0}
                totalAmt={subscriptionDetails.pieceLimitBytes || 1}
            />
        </div>
    );
};

export default PieceSubscriptionDetails;
