import React, { useEffect, useState } from "react";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Line,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
} from "recharts";

import "./insights.css";
import PageLayout from "../../../components/pageLayout";
import PracticeInsights from "./practiceInsights";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";

const Insights = () => {
    return (
        <PageLayout disableSideComponent>
            <h3>PracticePro</h3>
            <h2>Insights</h2>
            <PracticeInsights />
            <div className="insight-grid">
                <PracticeCountsPerTime />
            </div>
        </PageLayout>
    );
};

const PracticeCountsPerTime = () => {
    const [timeEnum, setTimeEnum] = useState("day");
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/insights/practices/time?timeEnum=day`
    );
    const [data, setData] = useState([]);

    function handleTicks() {
        let ticks = [];
        let dayCount = new Date().getDate();

        for (let i = dayCount; i >= 0; i--) {
            const date = new Date();
            date.setHours(0);
            date.setMilliseconds(0);
            date.setSeconds(0);
            date.setMinutes(0);
            date.setDate(i + 1);
            ticks.push(date.getTime());
        }
        return ticks;
    }

    function renderDay(time) {
        const dateString = new Date(time).toLocaleString();

        return dateString.split(",")[0];
    }

    const ticks = handleTicks();

    useEffect(() => {
        if (response) {
            let data = ticks.map((value) => {
                return { date: value, count: 0 };
            });

            data.map(({ date: date1, count: count1 }, i) => {
                response.data?.map(({ date: date2, count: count2 }) => {
                    if (date2 === date1) {
                        data[i] = { date: date1, count: count2 };
                    }
                });
            });
            setData(data);
        }
    }, [response]);

    return (
        <div className="paper">
            <h4>Practice Sessions This Month</h4>
            <ResponsiveContainer width={"100%"} height={200}>
                <LineChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        fontSize={"8px"}
                        dataKey="date"
                        ticks={ticks}
                        domain={["dataMin", "dataMax"]}
                        type="number"
                        interval={0}
                        tickFormatter={(value) => renderDay(value)}
                    />
                    <YAxis allowDecimals={false} />
                    <Tooltip labelFormatter={(value) => renderDay(value)} />
                    <Line
                        type="monotone"
                        dataKey="count"
                        stroke="var(--accent)"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

const PracticeCountsPerStudio = () => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/insights/practices/studio`
    );
    const [data, setData] = useState([]);

    useEffect(() => {
        if (response) {
            setData(response.data);
        }
    }, [response]);

    return (
        <div className="paper">
            <h4>Practice Sessions Per Studio</h4>
            <ResponsiveContainer width={"100%"} height={200}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey={"count"}
                        nameKey={"name"}
                        cx="50%"
                        cy="50%"
                        outerRadius={50}
                        fill="var(--accent)"
                    />
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};
export default Insights;
