import React, { useEffect, useState, useRef } from "react";
import AudioContext from "./contexts/AudioContext";
import autoCorrelate from "./libs/AutoCorrelate";
import {
    noteFromPitch,
    centsOffFromPitch,
    getDetunePercent,
} from "./libs/Helpers";
import "./tuner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faTimes } from "@fortawesome/free-solid-svg-icons";

const audioCtx = AudioContext.getAudioContext();
const analyserNode = AudioContext.getAnalyser();
const buflen = 2048;
var buf = new Float32Array(buflen);

const noteStrings = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
];

function Tuner() {
    const [source, setSource] = useState(null);
    const [started, setStarted] = useState(false);
    const [pitchNote, setPitchNote] = useState("C");
    const [pitchScale, setPitchScale] = useState("4");
    const [pitch, setPitch] = useState("0 Hz");
    const [detune, setDetune] = useState("0");
    const [notification, setNotification] = useState(false);
    const [open, setOpen] = useState(false);

    const containerRef = useRef(null);
    const boxRef = useRef(null);
    const isClicked = useRef(false);
    const coords = useRef({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0,
    });
    const animationFrameRef = useRef(null);

    const updatePitch = (time) => {
        analyserNode.getFloatTimeDomainData(buf);
        var ac = autoCorrelate(buf, audioCtx.sampleRate);
        if (ac > -1) {
            let note = noteFromPitch(ac);
            let sym = noteStrings[note % 12];
            let scl = Math.floor(note / 12) - 1;
            let dtune = centsOffFromPitch(ac, note);
            setPitch(parseFloat(ac).toFixed(2) + " Hz");
            setPitchNote(sym);
            setPitchScale(scl);
            setDetune(dtune);
            setNotification(false);
        }
    };

    useEffect(() => {
        if (source != null) {
            source.connect(analyserNode);
        }

        const box = boxRef.current;
        const container = containerRef.current;

        if (!box || !container) return;

        const onMouseDown = (e) => {
            if (e.target.closest(".tuner-start")) return;

            isClicked.current = true;
            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;
            container.style.cursor = "grabbing";
        };

        const onMouseUp = () => {
            isClicked.current = false;
            coords.current.lastX = box.offsetLeft;
            coords.current.lastY = box.offsetTop;
            cancelAnimationFrame(animationFrameRef.current);
            container.style.cursor = "grab";
        };

        const onMouseMove = (e) => {
            if (!isClicked.current) return;

            const nextX =
                e.clientX - coords.current.startX + coords.current.lastX;
            const nextY =
                e.clientY - coords.current.startY + coords.current.lastY;

            const updatePosition = () => {
                box.style.top = `${nextY}px`;
                box.style.left = `${nextX}px`;
                animationFrameRef.current =
                    requestAnimationFrame(updatePosition);
            };

            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = requestAnimationFrame(updatePosition);
        };

        box.addEventListener("mousedown", onMouseDown);
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseleave", onMouseUp);

        return () => {
            box.removeEventListener("mousedown", onMouseDown);
            window.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseleave", onMouseUp);
        };
    }, [source]);

    useEffect(() => {
        const interval = setInterval(updatePitch, 1);
        return () => clearInterval(interval);
    }, []);

    const start = async () => {
        const box = boxRef.current;

        if (!box) return;

        box.style.top = "50vh";
        box.style.left = "50vw";

        box.style.translate = "-50% -50%";

        const input = await getMicInput();
        if (audioCtx.state === "suspended") {
            await audioCtx.resume();
        }
        setStarted(true);
        setNotification(true);
        setTimeout(() => setNotification(false), 5000);
        setSource(audioCtx.createMediaStreamSource(input));
    };

    const stop = () => {
        if (source) {
            source.disconnect(analyserNode);
            setStarted(false);
        }
    };

    const toggleTuner = () => {
        setOpen(!open);
        if (!open) {
            start();
        } else {
            stop();
        }
    };

    const getMicInput = () => {
        return navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: true,
                autoGainControl: true,
                noiseSuppression: true,
                latency: 0,
            },
        });
    };

    return (
        <div ref={containerRef}>
            <div className={notification ? "notification" : "no-notification"}>
                Please, bring your instrument near to the microphone!
            </div>
            <div
                ref={boxRef}
                className="box"
                style={{
                    position: "absolute",
                    top: "50vh",
                    left: "50vw",
                    translate: "-50% - 50%",
                    cursor: "grab",
                    zIndex: 10,
                }}
            >
                <div className={open ? "tuner" : "tuner-hidden"}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="close-button"
                        onClick={toggleTuner}
                    />
                    <div className="tuner-note-scale">
                        <span className={started ? "tuner-note" : ""}>
                            {pitchNote}
                        </span>
                        <span className="tuner-scale">{pitchScale}</span>
                    </div>
                    <div className="detune-container">
                        <div
                            className="detune"
                            style={{
                                width:
                                    (detune < 0
                                        ? getDetunePercent(detune)
                                        : "50") + "%",
                            }}
                        ></div>
                        <span className="detune-center">I</span>
                        <div
                            className="detune"
                            style={{
                                width:
                                    (detune > 0
                                        ? getDetunePercent(detune)
                                        : "50") + "%",
                            }}
                        ></div>
                    </div>
                    <div className="pitch">
                        <span>{pitch}</span>
                    </div>
                </div>
            </div>
            <button className="tuner-start" onClick={toggleTuner}>
                <FontAwesomeIcon icon={faMicrophone} fontSize={"large"} />
                Tuner
            </button>
        </div>
    );
}

export default Tuner;
