import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";

const AssignmentDetails = ({ id }) => {
    let { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/assignments/${id}`
    );
    const [piece, setPiece] = useState({});

    useEffect(() => {
        if (response) {
            setPiece(response.data);
        }
    }, [response]);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim" />
            </div>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <h2>{piece.name}</h2>
            <iframe src={piece.embedLink} height={500} />
            <button onClick={() => {}}>Practice This Piece</button>
        </div>
    );
};

export default AssignmentDetails;
