import React from "react";

import "./pageSummary.css";

const PageSummary = ({ children }) => {
    return (
        <div className="summary">
            <div className="summary-container">{children}</div>
        </div>
    );
};

export default PageSummary;
