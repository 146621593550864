import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./helpSection.css";

const HelpSection = ({ title, message, flip }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            className="help-section"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <FontAwesomeIcon icon={faQuestionCircle} className="help-icon" />
            {showTooltip && (
                <div className="tooltip-container">
                    <div className={flip ? "tooltip-flipped" : "tooltip"}>
                        <h4>{title || "Tooltip"}</h4>
                        <p>{message}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HelpSection;
