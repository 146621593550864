import React, { useEffect, useState } from "react";
import fetchWithHeaders from "./fetchWithHeaders";
import Field from "./Field/field";
import SideDrawer from "./SideDrawer/sideDrawer";
import PickTagscomponent from "./PickTagsComponent/pickTagsComponent";
import useFetchWithHeaders from "../hooks/usefetchWithHeaders";
import { useAlert } from "./AlertBanner/alertProvider";
import { useRefreshKey } from "./RefreshProvider/refreshProvider";
import ColorOptions from "./Customization/colorOptions";
import IconOptions from "./Customization/iconOptions";

const EditPieceComponent = ({ id, isOpen, setIsOpen }) => {
    const [pieceData, setPieceData] = useState({});
    const { showAlert } = useAlert();
    const { refreshKey, refresh } = useRefreshKey();
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/pieces/${id}?refreshKey=${refreshKey}`
    );

    useEffect(() => {
        if (response) {
            setPieceData(response.data);
        }
    }, [response]);

    function updatePiece(event) {
        event.preventDefault();
        fetchWithHeaders("PATCH", `/pieces/${id}`, pieceData).then(
            ({ status, data, error }) => {
                if (error) {
                    showAlert(
                        "There was an error updating the piece: " + error,
                        "error"
                    );
                    return;
                } else {
                    showAlert("Successfully updated piece", "success");
                    refresh();
                    setIsOpen(false);
                }
            }
        );
    }

    function handleChange(event) {
        setPieceData((previous) => {
            return { ...previous, [event.target.name]: event.target.value };
        });
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return (
            <p className="invalid">There was an error loading this content</p>
        );
    }

    return (
        <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <h2>Piece Properties</h2>
            <form onSubmit={updatePiece} style={{ marginBottom: "2rem" }}>
                <Field
                    label={"Title"}
                    name={"title"}
                    value={pieceData?.name}
                    onChange={handleChange}
                    required
                />
                <Field
                    label={"Description"}
                    name={"description"}
                    value={pieceData?.description}
                    onChange={handleChange}
                />
                <Field
                    label={"Composer"}
                    name={"composer"}
                    value={pieceData?.composer}
                    onChange={handleChange}
                    required
                />
                <Field
                    label={"Arranger"}
                    name={"arranger"}
                    value={pieceData?.arranger}
                    onChange={handleChange}
                />

                <ColorOptions
                    value={pieceData.colorId}
                    onChange={handleChange}
                />
                <IconOptions value={pieceData.iconId} onChange={handleChange} />

                <PickTagscomponent
                    value={pieceData?.tags}
                    onChange={(tags) => {
                        setPieceData((previous) => {
                            return { ...previous, tags: tags };
                        });
                    }}
                />
                <div className="button-group">
                    <button type="submit">Update</button>
                    <button type="button" onClick={() => setIsOpen(false)}>
                        Cancel
                    </button>
                </div>
            </form>
        </SideDrawer>
    );
};

export default EditPieceComponent;
