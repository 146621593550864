import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Practice from "./Practice/practice";
import Journal from "./Journal/journal";
import { UserContext } from "../../components/userProvider";

import "./dashboard.css";
import PiecesPage from "./Pieces/piecesPage";
import Settings from "./Settings/settings";
import Students from "./Students/students";
import Feedback from "./Feedback/feedback";
import Support from "./Support/support";
import Insights from "./Insights/insights";
import PageLayout from "../../components/pageLayout";
import PiecePage from "./Pieces/piecePage";
import TagsPage from "./Tags/tagsPage";
import TeacherComponent from "../../components/teacherComponent";
import AdminComponent from "../../components/adminComponent";
import Users from "./Users/users";
import { jwtDecode } from "jwt-decode";
import UserPage from "./Users/userPage";

const Dashboard = () => {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return <PageLayout loading={loading} />;
    }

    const decoded = jwtDecode(localStorage.getItem("auth-token"));

    if (decoded.subscriptionIsValid !== null && !decoded.subscriptionIsValid) {
        return <Navigate to={"/invalid-membership"} />;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Navigate
                        to={
                            user?.userType === "student"
                                ? "/dashboard/journal"
                                : "/dashboard/insights"
                        }
                    />
                }
            />
            <Route
                path="pieces"
                element={
                    user?.userType !== "teacher" ? (
                        <Navigate to={"/not-found"} />
                    ) : (
                        <PiecesPage />
                    )
                }
            />
            <Route path="practice/*" element={<Practice />} />
            <Route
                path="journal"
                element={
                    user?.userType === "student" ? (
                        <Journal />
                    ) : (
                        <Navigate to={"/dashboard/insights"} />
                    )
                }
            />
            <Route
                path="insights"
                element={
                    user?.userType === "student" ? (
                        <Navigate to={"/dashboard/journal"} />
                    ) : (
                        <Insights />
                    )
                }
            />

            <Route
                path="students"
                element={
                    <TeacherComponent>
                        <Students />
                    </TeacherComponent>
                }
            />
            <Route
                path="users"
                element={
                    user?.userType !== "admin" ? (
                        <Navigate to={"/dashboard"} />
                    ) : (
                        <Users />
                    )
                }
            />
            <Route
                path="users/:userId"
                element={
                    user?.userType === "student" ? (
                        <Navigate to={"/dashboard"} />
                    ) : (
                        <UserPage />
                    )
                }
            />
            <Route path="pieces/:pieceId" element={<PiecePage />} />
            <Route path="settings" element={<Settings />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<Navigate to={"/not-found"} />} />
        </Routes>
    );
};

export default Dashboard;
