import React, { useEffect, useRef, useState } from "react";
import "./metronome.css";
import highWoodBlockWav from "../../audio/High_Woodblock.wav";
import count1 from "../../audio/1.wav";
import count2 from "../../audio/2.wav";
import count3 from "../../audio/3.wav";
import count4 from "../../audio/4.wav";
import count5 from "../../audio/5.wav";
import count6 from "../../audio/6.wav";
import count7 from "../../audio/7.wav";
import count8 from "../../audio/8.wav";
import Field from "../../components/Field/field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDrum,
    faPlay,
    faStop,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import quarterNote from "../../practice-icons/quarter-note.png";
import eighthNote from "../../practice-icons/eighth-note.png";
import sixteenthNote from "../../practice-icons/sixteenth-note.png";

const Metronome = () => {
    const [metronomeRunning, setMetronomeRunning] = useState(false);
    const [bpm, setBpm] = useState(60);
    const [open, setOpen] = useState(false);
    const [taps, setTaps] = useState([]);
    const [currentBeat, setCurrentBeat] = useState(0);
    const [currentSubBeat, setCurrentSubBeat] = useState(0);
    const [subdivision, setSubdivision] = useState(1);
    const [voiceCounting, setVoiceCounting] = useState(false);
    const [meter, setMeter] = useState({ numerator: 4, denominator: 4 });
    const metronomeInterval = useRef(null);

    const containerRef = useRef(null);
    const boxRef = useRef(null);
    const isClicked = useRef(false);
    const coords = useRef({ startX: 0, startY: 0, lastX: 0, lastY: 0 });
    const animationFrameRef = useRef(null);

    const audioContext = useRef(
        new (window.AudioContext || window.webkitAudioContext)()
    );
    const buffers = useRef([]);

    const loadAudioFiles = async () => {
        const audioUrls = [
            count1,
            count2,
            count3,
            count4,
            count5,
            count6,
            count7,
            count8,
            highWoodBlockWav,
        ];
        const loadedBuffers = await Promise.all(
            audioUrls.map(async (url) => {
                const response = await fetch(url);
                const arrayBuffer = await response.arrayBuffer();
                return audioContext.current.decodeAudioData(arrayBuffer);
            })
        );
        buffers.current = loadedBuffers;
    };

    useEffect(() => {
        loadAudioFiles();
    }, []);

    const playAudio = (buffer) => {
        const source = audioContext.current.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.current.destination);
        source.start();
    };

    const tick = () => {
        setCurrentSubBeat((prevSubBeat) => {
            const nextSubBeat = (prevSubBeat + 1) % subdivision;
            if (nextSubBeat === 0) {
                setCurrentBeat((prevBeat) => {
                    const newBeat = (prevBeat + 1) % meter.numerator;
                    if (voiceCounting) {
                        playAudio(buffers.current[newBeat]);
                    }
                    return newBeat;
                });
            }
            playAudio(buffers.current[8]);
            return nextSubBeat;
        });
    };

    useEffect(() => {
        if (metronomeRunning) {
            const interval = 60000 / (bpm * subdivision);
            clearInterval(metronomeInterval.current);
            metronomeInterval.current = setInterval(() => {
                tick();
            }, interval);
        } else {
            clearInterval(metronomeInterval.current);
        }

        return () => clearInterval(metronomeInterval.current);
    }, [metronomeRunning, bpm, subdivision, voiceCounting, meter]);

    const startMetronome = () => {
        const box = boxRef.current;

        if (!box) return;

        box.style.top = "50vh";
        box.style.left = "50vw";

        box.style.translate = "50% -50%";
        setMetronomeRunning(true);
    };

    const stopMetronome = () => {
        setMetronomeRunning(false);
    };

    const [counter, setCounter] = useState(0);

    const handleTap = () => {
        const now = Date.now();
        const newTaps = [...taps, now];
        setTaps(newTaps.slice(-4));
        setCounter((prevCounter) => prevCounter + 1);

        if (counter % 4 === 0 && counter !== 0) {
            const intervals = newTaps
                .slice(1)
                .map((tap, index) => tap - newTaps[index]);
            const averageInterval =
                intervals.reduce((a, b) => a + b) / intervals.length;
            const bpm = 60000 / averageInterval;
            setBpm(Math.round(bpm));
        }
    };

    useEffect(() => {
        const box = boxRef.current;
        const container = containerRef.current;

        if (!box || !container) return;

        const onMouseDown = (e) => {
            if (e.target.closest(".bpm-slider input")) return;
            isClicked.current = true;
            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;
            container.style.cursor = "grabbing";
        };

        const onMouseUp = () => {
            isClicked.current = false;
            coords.current.lastX = box.offsetLeft;
            coords.current.lastY = box.offsetTop;
            cancelAnimationFrame(animationFrameRef.current);
            container.style.cursor = "grab";
        };

        const onMouseMove = (e) => {
            if (!isClicked.current) return;

            const nextX =
                e.clientX - coords.current.startX + coords.current.lastX;
            const nextY =
                e.clientY - coords.current.startY + coords.current.lastY;

            const updatePosition = () => {
                box.style.top = `${nextY}px`;
                box.style.left = `${nextX}px`;
                animationFrameRef.current =
                    requestAnimationFrame(updatePosition);
            };

            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = requestAnimationFrame(updatePosition);
        };

        box.addEventListener("mousedown", onMouseDown);
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseleave", onMouseUp);

        return () => {
            box.removeEventListener("mousedown", onMouseDown);
            window.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseleave", onMouseUp);
        };
    }, []);

    const handleMeterChange = (value) => {
        const [num, denom] = value.split("/").map(Number);
        setMeter({ numerator: num, denominator: denom });
    };

    const toggleMetronome = () => {
        setOpen(!open);
        if (!open) {
            startMetronome();
        } else {
            stopMetronome();
        }
    };

    return (
        <div ref={containerRef} className="container">
            <div
                ref={boxRef}
                className="box"
                style={{
                    position: "absolute",
                    top: "50vh",
                    left: "50vw",
                    cursor: "grab",
                    translate: "50% -50%",
                    zIndex: 10,
                }}
            >
                <div className={open ? "metronome" : "metronome-hidden"}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="close-button"
                        onClick={() => {
                            stopMetronome();
                            setOpen(false);
                        }}
                    />
                    <div className="bpm">
                        <h1>{bpm}</h1>
                        <div className="bpm-slider">
                            <h3>10</h3>
                            <Field
                                id="bpm"
                                name={"bpm"}
                                label={"BPM"}
                                type="range"
                                value={bpm}
                                min={10}
                                max={1000}
                                onChange={(e) => setBpm(e.target.value)}
                            />
                            <h3>1000</h3>
                        </div>
                    </div>
                    <div
                        className={`beat-container ${
                            meter.numerator === 6
                                ? "beat-container-6"
                                : meter.numerator === 8
                                ? "beat-container-8"
                                : ""
                        }`}
                    >
                        {[...Array(meter.numerator)].map((_, i) => (
                            <div
                                key={i}
                                className={`beat-indicator ${
                                    i === currentBeat ? "active-beat" : ""
                                }`}
                            ></div>
                        ))}
                    </div>
                    <div className="button-group">
                        <button
                            className="start-button"
                            onClick={startMetronome}
                        >
                            <FontAwesomeIcon icon={faPlay} /> Start
                        </button>
                        <button className="stop-button" onClick={stopMetronome}>
                            <FontAwesomeIcon icon={faStop} /> Stop
                        </button>
                        <button className="tap-button" onClick={handleTap}>
                            Tap
                        </button>
                    </div>
                    <div className="meter-selector">
                        <label style={{ fontSize: "15px" }}>Meter:</label>
                        <select
                            value={`${meter.numerator}/${meter.denominator}`}
                            onChange={(e) => handleMeterChange(e.target.value)}
                            style={{
                                fontSize: "20px",
                                padding: "10px",
                                width: "110px",
                            }}
                        >
                            <option value="2/4">2/4</option>
                            <option value="4/4">4/4</option>
                            <option value="6/8">6/8</option>
                        </select>
                    </div>
                    <div className="subdivision-selector">
                        <label
                            style={{ fontSize: "15px", marginBottom: "10px" }}
                        >
                            Subdivision:
                        </label>
                        <div className="subdivision-images">
                            <img
                                src={quarterNote}
                                alt="Quarter Notes"
                                className={`subdivision-image ${
                                    subdivision === 1
                                        ? "active-subdivision"
                                        : ""
                                }`}
                                onClick={() => setSubdivision(1)}
                            />
                            <img
                                src={eighthNote}
                                alt="Eighth Notes"
                                className={`subdivision-image ${
                                    subdivision === 2
                                        ? "active-subdivision"
                                        : ""
                                }`}
                                onClick={() => setSubdivision(2)}
                            />
                            <img
                                src={sixteenthNote}
                                alt="Sixteenth Notes"
                                className={`subdivision-image ${
                                    subdivision === 4
                                        ? "active-subdivision"
                                        : ""
                                }`}
                                onClick={() => setSubdivision(4)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <button className="metronome-start" onClick={toggleMetronome}>
                <FontAwesomeIcon icon={faDrum} fontSize={"large"} />
                Metronome
            </button>
        </div>
    );
};

export default Metronome;
