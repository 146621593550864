import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import fetchWithHeaders from "../../components/fetchWithHeaders";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import "./checkout.css";
import Field from "../../components/Field/field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LogoWithText from "../../components/Logos/logoWithText";

const Register = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [userInfo, setUserInfo] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const priceId = searchParams.get("priceId");
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/stripe/plans/${priceId}`
    );
    const [planData, setPlanData] = useState({});
    useEffect(() => {
        if (response) {
            setPlanData(response.data);
        }
    }, [response]);

    function handleChange(e) {
        setUserInfo((previous) => {
            return { ...previous, [e.target.name]: e.target.value };
        });
    }

    const handleCheckout = async (event) => {
        event.preventDefault();
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/stripe/create-checkout-session",
                {
                    priceId,
                    ...userInfo,
                }
            );
            if (error) throw new Error(error);

            if (data?.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                throw new Error("Error: No redirect URL found in response");
            }
        } catch (error) {
            console.log(error);
            setIsInvalid(true);
        }
    };

    if (loading) {
        return (
            <div className="page centered">
                <div className="loading-paper">
                    <div className="loading-anim"></div>
                    <LogoWithText width={150} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="page centered">
                <div className="paper">
                    <p className="invalid">
                        There was an error loading this content
                    </p>
                </div>
            </div>
        );
    }

    const { name, description, cadence, price } = planData;

    return (
        <div className="checkout-page">
            <div className="plan-details">
                <h3>Membership Plan Selected:</h3>
                <h1>{name}</h1>
                <p>{description}</p>
                <h3>
                    {`Paid ${
                        cadence === "Monthly" ? cadence : "Annually"
                    } at $${Math.fround(price / 100).toFixed(0)}`}
                </h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faCheck} className="icon" />
                        3GB of Piece PDF storage
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} className="icon" />
                        Detailed Practice Insights
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} className="icon" />
                        24/7 Tech Support Team
                    </li>
                </ul>
                <button
                    onClick={() => navigate("/membership")}
                    className="button-secondary back"
                >
                    Back to Membership Selection
                </button>
            </div>
            <div className="checkout-info">
                <h3>PracticePro</h3>
                <h1>Checkout Info</h1>
                <p>
                    Thank you for your interest in our membership plans! Once
                    you've completed payment, we'll send you a one time password
                    to log in and start teaching!
                </p>
                <form onSubmit={handleCheckout}>
                    <Field
                        name="fname"
                        label="First Name"
                        value={userInfo.fname}
                        onChange={handleChange}
                    />
                    <Field
                        name="lname"
                        label="Last Name"
                        value={userInfo.lname}
                        onChange={handleChange}
                    />
                    {isInvalid && (
                        <label className="invalid">
                            This email is already being used
                        </label>
                    )}
                    <Field
                        name="email"
                        label="Email"
                        value={userInfo.email}
                        onChange={(e) => {
                            setIsInvalid(false);
                            handleChange(e);
                        }}
                    />
                    <button type="submit">Continue To Checkout</button>
                </form>
            </div>
        </div>
    );
};

export default Register;
