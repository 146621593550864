import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import Popup from "../../../components/Popup/popup";
import Switch from "../../../components/Switch/switch";
import { formatMS } from "../../../lib/format";
import { useAlert } from "../../../components/AlertBanner/alertProvider";

const TIME_OPTIONS = [
    { title: "Quick Jam", value: 30 },
    { title: "Rockstar Session", value: 45 },
    { title: "Maestro Mode", value: 60 },
    { title: "Epic Symphony", value: 90 },
    { title: "Masterpiece Marathon", value: 120 },
    { title: "Legendary Concert", value: 180 },
];

const StartPractice = () => {
    const [isStarting, setIsStarting] = useState(false);
    const [generatePlan, setGeneratePlan] = useState(true);
    const [customTime, setCustomTime] = useState(45);
    const [useCustomTime, setUseCustomTime] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectingOption, setSelectingOption] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const { showAlert } = useAlert();

    const handleStartSession = () => {
        try {
            const { status, data, error } = fetchWithHeaders(
                "POST",
                "/practices",
                {
                    generatePlan,
                    planSettings: {
                        totalTime: useCustomTime
                            ? customTime
                            : TIME_OPTIONS[selectedOption]?.value,
                    },
                }
            );
            if (error) throw new Error(error);
            window.location.reload(false);
        } catch (error) {
            showAlert(
                "There was an error starting the session: " + error,
                "error"
            );
            console.log(error);
        }
    };

    function handleClickTimeOption(index) {
        setSelectedOption(index);
        setUseCustomTime(false);
        setSelectingOption(false);
    }

    useEffect(() => {
        async function checkForAssignments() {
            fetchWithHeaders("GET", "/assignments")
                .then(({ status, data, error }) => {
                    if (error) {
                        throw new Error(error);
                    }
                    setAssignments(data);
                })
                .catch((error) => {
                    console.log(error);
                    showAlert(
                        "There was an error loading your assignments: " + error,
                        "error"
                    );
                    setAssignments([]);
                });
        }
        if (isStarting) {
            checkForAssignments();
        }
    }, [isStarting]);

    return (
        <div className="button-container">
            <Popup open={isStarting} setIsOpen={setIsStarting}>
                <h2
                    style={{
                        color: "var(--secondary)",
                        marginBottom: "1rem",
                    }}
                >
                    Session Preferences
                </h2>

                {assignments?.length < 1 && (
                    <p className="invalid">
                        You can generate a plan once your teacher gives you
                        assignments
                    </p>
                )}
                <Switch
                    leftOption={""}
                    rightOption={"Generate Plan"}
                    checked={generatePlan}
                    onChange={(e) => setGeneratePlan(e.target.checked)}
                    disabled={assignments?.length < 1}
                />
                {generatePlan && assignments.length > 1 && (
                    <React.Fragment>
                        <div className="practice-options-container">
                            <div
                                className="practice-options-select"
                                onClick={() =>
                                    setSelectingOption(!selectingOption)
                                }
                            >
                                <h2>
                                    {useCustomTime
                                        ? "Custom"
                                        : TIME_OPTIONS[selectedOption]?.title}
                                </h2>
                                <h3>
                                    {useCustomTime
                                        ? formatMS(customTime * 60 * 1000, true)
                                        : formatMS(
                                              TIME_OPTIONS[selectedOption]
                                                  ?.value *
                                                  60 *
                                                  1000,
                                              true
                                          )}
                                </h3>
                            </div>
                            <div
                                className={
                                    selectingOption
                                        ? "practice-options-grid"
                                        : "practice-options-grid  practice-options-grid-hidden"
                                }
                            >
                                {TIME_OPTIONS.map(({ title, value }, i) => {
                                    return (
                                        <div
                                            className="practice-options-grid-item"
                                            onClick={() =>
                                                handleClickTimeOption(i)
                                            }
                                            key={i}
                                        >
                                            <h2>{title}</h2>
                                            <h3>
                                                {formatMS(
                                                    value * 60 * 1000,
                                                    true
                                                )}
                                            </h3>
                                        </div>
                                    );
                                })}
                                <div
                                    className="practice-options-grid-item"
                                    style={{ gridColumn: "1 / span 3" }}
                                    onClick={() => {
                                        setUseCustomTime(true);
                                        setSelectingOption(false);
                                    }}
                                >
                                    <h3>Custom</h3>
                                </div>
                            </div>
                        </div>

                        <label
                            className={
                                useCustomTime
                                    ? "custom-slider"
                                    : "custom-slider custom-slider-hidden"
                            }
                        >
                            Custom Time
                            <input
                                type="range"
                                min={15}
                                max={180}
                                value={customTime}
                                onChange={(e) => setCustomTime(e.target.value)}
                            />
                        </label>
                    </React.Fragment>
                )}
                <button onClick={handleStartSession}>Start Session</button>
            </Popup>
            <div
                className={"start-session"}
                onClick={() => setIsStarting(true)}
            >
                <FontAwesomeIcon icon={faPlay} />
                <h2>Start Session</h2>
            </div>
        </div>
    );
};
export default StartPractice;
