import { faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import Popup from "../../../components/Popup/popup";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../components/AlertBanner/alertProvider";

const TIME_OPTIONS = [
    { title: "Quick Jam", value: 30 },
    { title: "Rockstar Session", value: 45 },
    { title: "Maestro Mode", value: 60 },
    { title: "Epic Symphony", value: 90 },
    { title: "Masterpiece Marathon", value: 120 },
    { title: "Legendary Concert", value: 180 },
];

const PracticeTimer = ({ practiceId, totalTime }) => {
    const [elapsedTime, setElapsedTime] = useState({
        minutes: "00",
        seconds: "00",
    });
    const [startTime, setStartTime] = useState(new Date());
    const [isStopping, setIsStopping] = useState(false);
    const navigate = useNavigate();
    const { showAlert } = useAlert();

    useEffect(() => {
        let timer;

        timer = setInterval(() => {
            const totalElapsed = new Date() - startTime + (totalTime || 0);
            const minutes = Math.floor(totalElapsed / 60000);
            const seconds = Math.floor((totalElapsed % 60000) / 1000).toFixed(
                0
            );
            const formattedSec = seconds < 10 ? "0" + seconds : seconds;
            const formattedMin = minutes < 10 ? "0" + minutes : minutes;
            setElapsedTime({
                minutes: formattedMin,
                seconds: formattedSec,
            });
        }, 100);

        return () => clearInterval(timer);
    }, [startTime, totalTime]);

    const handleEndSession = () => {
        fetchWithHeaders("PATCH", `/practices/${practiceId}`)
            .then(({ status, data, error }) => {
                if (error) throw new Error(error);
                setElapsedTime({ minutes: "00", seconds: "00" });
                setIsStopping(false);
                navigate("/dashboard/journal");
            })
            .catch((error) => {
                console.log(error);
                showAlert(
                    "There was an error ending the session: " + error,
                    "error"
                );
            });
    };

    if (!practiceId) {
        return;
    }

    return (
        <div className="timer-container">
            <button
                className="timer-stop-button"
                style={{ width: "40px", height: "40px" }}
                onClick={() => {
                    if (!isStopping) {
                        setIsStopping(true);
                    }
                }}
            >
                <FontAwesomeIcon icon={faStop} />
            </button>
            <div className="timer">
                <h2>{`${elapsedTime.minutes}:${elapsedTime.seconds}`}</h2>
            </div>
            <div
                className={
                    isStopping
                        ? "end-warning"
                        : "end-warning end-warning-hidden"
                }
            >
                <h4>Are you sure you want to end this practice session?</h4>
                <div className="button-group">
                    <button onClick={handleEndSession}>
                        Yes, Save & End Session
                    </button>
                    <button onClick={() => setIsStopping(false)}>
                        No, Keep Practicing
                    </button>
                </div>
            </div>
        </div>
    );
};
export default PracticeTimer;
