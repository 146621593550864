import React, { useEffect, useState } from "react";
import fetchWithHeaders from "../fetchWithHeaders";
import { formatMS } from "../../lib/format";

import "./planGenerator.css";
import { useAlert } from "../AlertBanner/alertProvider";
import { useNavigate } from "react-router-dom";

const TIME_OPTIONS = [
    { title: "Quick Jam", value: 30 },
    { title: "Rockstar Session", value: 45 },
    { title: "Maestro Mode", value: 60 },
    { title: "Epic Symphony", value: 90 },
    { title: "Masterpiece Marathon", value: 120 },
    { title: "Legendary Concert", value: 180 },
];

const PracticePlanGenerator = () => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedPlan, setGeneratedPlan] = useState(null);
    const [customTime, setCustomTime] = useState(45);
    const [useCustomTime, setUseCustomTime] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectingOption, setSelectingOption] = useState(false);
    const [assignments, setAssignments] = useState([]);
    const { showAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function checkForAssignments() {
            fetchWithHeaders("GET", "/assignments")
                .then(({ status, data, error }) => {
                    if (error) {
                        throw new Error(error);
                    }
                    setAssignments(data);
                })
                .catch((error) => {
                    console.log(error);
                    showAlert(
                        "There was an error loading your assignments: " + error,
                        "error"
                    );
                    setAssignments([]);
                });
        }

        checkForAssignments();
    }, []);

    async function generatePlan() {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "GET",
                `/plans/generate?totalTime=${
                    useCustomTime
                        ? customTime
                        : TIME_OPTIONS[selectedOption]?.value
                }`
            );
            if (error) throw new Error(error);
            setGeneratedPlan(data);
            showAlert("Successfully generated practice plan", "success");
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error generating the plan: " + error,
                "error"
            );
        }
    }

    async function startPlan() {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                `/plans`,
                { planData: generatedPlan }
            );
            if (error) throw new Error(error);
            showAlert("Successfully started Practice Plan", "success");
            window.location.reload(false);
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error starting the plan: " + error,
                "error"
            );
        }
    }

    function handleClickTimeOption(index) {
        setSelectedOption(index);
        setUseCustomTime(false);
        setSelectingOption(false);
    }

    if (generatedPlan) {
        return (
            <div>
                <h2>Generated Practice Plan</h2>
                <ul className="generated-plan">
                    {generatedPlan?.sections?.map(
                        (
                            {
                                name,
                                description,
                                composer,
                                arranger,
                                allocatedTime,
                            },
                            i
                        ) => {
                            return (
                                <li key={i}>
                                    <h4 style={{ margin: 0 }}>
                                        {i + 1}. {name} - {composer}
                                    </h4>
                                    <h3>
                                        {formatMS(
                                            allocatedTime * 60 * 1000,
                                            true
                                        )}
                                    </h3>
                                </li>
                            );
                        }
                    )}
                </ul>
                <div className="button-group">
                    <button onClick={startPlan}>Start This Plan</button>
                    <button
                        onClick={() => {
                            setGeneratedPlan(null);
                            setIsGenerating(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    if (!isGenerating) {
        return (
            <div>
                <h3>No practice plan for this session</h3>
                {assignments?.length < 1 && (
                    <p className="invalid">
                        You can generate a plan once your teacher gives you
                        assignments
                    </p>
                )}
                <div className="button-group">
                    <button
                        onClick={() => setIsGenerating(true)}
                        disabled={assignments?.length < 1}
                    >
                        Generate Practice Plan
                    </button>
                    <button onClick={() => navigate("/dashboard/practice")}>
                        Back To Practice Home
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div>
            <h2>Practice Plan</h2>
            <div className="practice-options-container">
                <div
                    className="practice-options-select"
                    onClick={() => setSelectingOption(!selectingOption)}
                >
                    <h2>
                        {useCustomTime
                            ? "Custom"
                            : TIME_OPTIONS[selectedOption]?.title}
                    </h2>
                    <h3>
                        {useCustomTime
                            ? formatMS(customTime * 60 * 1000, true)
                            : formatMS(
                                  TIME_OPTIONS[selectedOption]?.value *
                                      60 *
                                      1000,
                                  true
                              )}
                    </h3>
                </div>
                <div
                    className={
                        selectingOption
                            ? "practice-options-grid"
                            : "practice-options-grid  practice-options-grid-hidden"
                    }
                >
                    {TIME_OPTIONS.map(({ title, value }, i) => {
                        return (
                            <div
                                className="practice-options-grid-item"
                                onClick={() => handleClickTimeOption(i)}
                            >
                                <h2>{title}</h2>
                                <h3>{formatMS(value * 60 * 1000, true)}</h3>
                            </div>
                        );
                    })}
                    <div
                        className="practice-options-grid-item"
                        style={{ gridColumn: "1 / span 3" }}
                        onClick={() => {
                            setUseCustomTime(true);
                            setSelectingOption(false);
                        }}
                    >
                        <h3>Custom</h3>
                    </div>
                </div>
            </div>

            <label
                className={
                    useCustomTime
                        ? "custom-slider"
                        : "custom-slider custom-slider-hidden"
                }
            >
                Custom Time
                <input
                    type="range"
                    min={15}
                    max={180}
                    value={customTime}
                    onChange={(e) => setCustomTime(e.target.value)}
                />
            </label>
            <div className="button-group">
                <button onClick={generatePlan}>Generate</button>
                <button onClick={() => setIsGenerating(false)}>Cancel</button>
            </div>
        </div>
    );
};

export default PracticePlanGenerator;
