import React, { useContext } from "react";
import { UserContext } from "./userProvider";

const StudentComponent = ({ children }) => {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim" />
            </div>
        );
    }

    return user?.userType === "student" && children;
};

export default StudentComponent;
