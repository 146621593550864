import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import "./practice.css";

const AssignmentToPractice = ({ planId }) => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/plans/${planId}`
    );
    const [assignmentToPractice, setAssignmentToPractice] = useState({});

    useEffect(() => {
        if (response) {
            for (const section of response.data?.sections) {
                setAssignmentToPractice(section);
                if (!section.endTime) {
                    break;
                }
            }
        }
    }, [response]);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    if (error) {
        return (
            <p className="invalid">There was an error loading this content</p>
        );
    }

    return (
        <div className="iframe-wrapper">
            <iframe
                src={assignmentToPractice?.embedLink}
                width={"100%"}
                height={"100%"}
                allowtransparency="true"
                border="0"
                style={{
                    borderStyle: "none",
                    borderRadius: "20px",
                    background: "white !important",
                    backgroundColor: "white !important",
                }}
                allowFullScreen
            />
        </div>
    );
};

export default AssignmentToPractice;
