import React, { useState } from "react";

import "./Tabs.css";
import { useSearchParams } from "react-router-dom";

const useTabs = (tabs, onTabClick) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let tabParam = searchParams.get("tab");

    function isValid(value) {
        if (isNaN(value)) {
            return false;
        }
        if (value >= tabs.length || value < 0) return false;
        var x = parseFloat(value);
        return (x | 0) === x;
    }

    let defaultTab = 0;

    if (isValid(tabParam)) {
        defaultTab = tabParam;
    } else {
        setSearchParams(`tab=${0}`);
    }
    const [activeTab, setActiveTab] = useState(defaultTab);

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (onTabClick != null) {
            onTabClick(index);
        }
        setSearchParams(`tab=${index}`);
    };

    function render() {
        return (
            <div className="tabs">
                <div className="tab-buttons">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => handleTabClick(index)}
                            className={index == activeTab ? "active" : ""}
                        >
                            <h3>{tab.label?.toUpperCase() || ""}</h3>
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    {tabs[activeTab]?.content || <></>}
                </div>
            </div>
        );
    }
    return [activeTab, render];
};

export default useTabs;
