import React, { createContext, useContext } from "react";
import useFetchWithHeaders from "../hooks/usefetchWithHeaders";
import { useRefreshKey } from "./RefreshProvider/refreshProvider";

export const UserContext = createContext();

export const useProfile = () => useContext(UserContext);

const UserProvider = ({ children }) => {
    const { refreshKey } = useRefreshKey();
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/profile?refreshKey=${refreshKey}`
    );
    return (
        <UserContext.Provider
            value={{ user: response?.data, loading: loading }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
