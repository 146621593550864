import React, { useState, useMemo, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./table.css";
import {
    faArrowDown,
    faArrowUp,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faTag,
} from "@fortawesome/free-solid-svg-icons";
import fetchWithHeaders from "../fetchWithHeaders";
import HelpSection from "../HelpSection/helpSection";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function TableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <thead>
            <tr>
                <th
                    style={{
                        width: "1%",
                        whiteSpace: "nowrap",
                        padding: "0 1rem",
                    }}
                >
                    <div
                        className={
                            rowCount > 0 && numSelected === rowCount
                                ? "selected-icon"
                                : "not-selected-icon"
                        }
                        onClick={onSelectAllClick}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                </th>
                {headCells.map((headCell) => (
                    <th
                        key={headCell.id}
                        style={{
                            ...headCell.style,
                            alignContent: headCell.numeric ? "right" : "left",
                        }}
                        className={headCell.responsive ? "responsive" : ""}
                        sortdirection={orderBy === headCell.id ? order : "asc"}
                    >
                        <div onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span style={{ marginLeft: "0.5rem" }}>
                                    {order === "desc" ? (
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    ) : (
                                        <FontAwesomeIcon icon={faArrowUp} />
                                    )}
                                </span>
                            ) : null}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
}

export default function Table({
    onSelect,
    rows,
    headCells,
    handleClick,
    orderByDefault,
    bulkActions,
    onFilterTags,
    emptyTableMessage,
    secondaryId,
    orderDefault = "asc",
}) {
    const [order, setOrder] = useState(orderDefault);
    const [orderBy, setOrderBy] = useState(orderByDefault);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filtered, setFiltered] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        setFiltered(rows);
    }, [rows]);

    useEffect(() => {
        function getTags() {
            fetchWithHeaders("GET", `/tags`)
                .then(({ status, data, error }) => {
                    if (error) throw new Error(error);
                    const newFilters = data.map((t) => {
                        return {
                            label: t,
                            active: false,
                        };
                    });
                    setFilters(newFilters);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        getTags();
    }, []);

    function handleActivateFilter(i) {
        if (filters?.length <= 0) return;
        let temp = [...filters];
        temp[i].active = !filters[i].active;

        setFilters(temp);
        setSelected([]);
        if (onFilterTags) {
            onFilterTags(
                temp
                    .filter((f) => f.active)
                    .map((f) => f.label)
                    .join(",")
            );
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (!selectedAll) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            setSelectedAll(true);
            return;
        }
        setSelectedAll(false);
        setSelected([]);
    };

    const handleSelect = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    useEffect(() => {
        if (onSelect) onSelect(selected);
    }, [selected]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(filtered, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [filtered, order, orderBy, page, rowsPerPage]
    );

    return (
        <div>
            <div
                className={
                    showFilters
                        ? "filters-container"
                        : "filters-container filters-container-hidden"
                }
            >
                {filters?.map(({ label, active }, i) => {
                    return (
                        <div
                            key={i}
                            className={
                                active ? "filter filter-active" : "filter"
                            }
                            onClick={() => handleActivateFilter(i)}
                        >
                            {label}
                        </div>
                    );
                })}
                <HelpSection
                    title="Tags"
                    message="Click a tag to filter results. You can add or remove tags from items when you create them, or visit their details page"
                />
            </div>
            <div className="table-container">
                <div className="toolbar">
                    <FontAwesomeIcon
                        onClick={() => setShowFilters(!showFilters)}
                        className="tag-icon"
                        icon={faTag}
                    />

                    {bulkActions?.map(({ action, label, requiresSelected }) => {
                        let disabled = false;
                        if (requiresSelected && selected.length === 0) {
                            disabled = true;
                        }
                        return (
                            <div
                                onClick={() =>
                                    disabled ? {} : action(selected)
                                }
                                style={{
                                    color: disabled ? "var(--disabled)" : "",
                                }}
                                className="bulk-action"
                                key={label}
                            >
                                {label}
                            </div>
                        );
                    })}
                    <div
                        className="bulk-action"
                        style={{ color: "var(--accent)" }}
                    >
                        {selected?.length > 0
                            ? selected?.length + " Selected"
                            : ""}
                    </div>
                </div>
                <table rules="none">
                    <TableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                    />
                    <tbody>
                        {visibleRows.map((row) => {
                            const isItemSelected = isSelected(row.id);
                            return (
                                <React.Fragment key={row.id}>
                                    <tr
                                        tabIndex={-1}
                                        style={{
                                            backgroundColor:
                                                secondaryId && row[secondaryId]
                                                    ? "#eeeeee"
                                                    : row.color,
                                        }}
                                    >
                                        <td>
                                            {isItemSelected ? (
                                                <div
                                                    className="selected-icon"
                                                    onClick={(event) =>
                                                        handleSelect(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                    />
                                                </div>
                                            ) : row.iconUrl ? (
                                                <img
                                                    className={`row-icon`}
                                                    src={row.iconUrl}
                                                    onClick={(event) =>
                                                        handleSelect(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <div
                                                    className="not-selected-icon"
                                                    onClick={(event) =>
                                                        handleSelect(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                ></div>
                                            )}
                                        </td>
                                        {headCells.map((cell) => {
                                            return (
                                                <td
                                                    style={cell.style}
                                                    key={cell.id}
                                                    onClick={(event) => {
                                                        if (handleClick)
                                                            handleClick(row.id);
                                                    }}
                                                    className={
                                                        cell.responsive
                                                            ? "responsive"
                                                            : ""
                                                    }
                                                >
                                                    {cell.formatter
                                                        ? cell.formatter(
                                                              row[cell.id]
                                                          )
                                                        : row[cell.id]}
                                                    {row[cell.secondaryId]}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    <tr className="spacer">
                                        <td colSpan={20}></td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                        {visibleRows.length <= 0 && (
                            <tr
                                className="no-hover-effect"
                                style={{ backgroundColor: "var(--primary)" }}
                            >
                                <td colSpan={5}>
                                    <h4 style={{ margin: 0 }}>
                                        {emptyTableMessage ||
                                            "Nothing matched your search"}
                                    </h4>
                                </td>
                            </tr>
                        )}
                        {emptyRows > 0 && (
                            <tr
                                style={{
                                    height: 28 * emptyRows,
                                    backgroundColor: "white",
                                }}
                            >
                                <td colSpan={9} />
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "0.5rem",
                    justifyContent: "right",
                    gap: "1rem",
                }}
            >
                <h4>{`${page * rowsPerPage + 1} - ${
                    (page + 1) * rowsPerPage > filtered.length
                        ? filtered.length
                        : (page + 1) * rowsPerPage
                } of ${filtered.length}`}</h4>
                <div>
                    <label>Rows Per Page</label>
                    <select onChange={handleChangeRowsPerPage}>
                        {[10, 20, 30].map((option) => {
                            return (
                                <option value={option} key={option}>
                                    {option}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "1rem",
                    }}
                >
                    <FontAwesomeIcon
                        onClick={(e) => {
                            if (page > 0) handleChangePage(e, page - 1);
                        }}
                        icon={faCaretLeft}
                    />
                    <FontAwesomeIcon
                        onClick={(e) => {
                            if (
                                page * rowsPerPage + rowsPerPage <
                                filtered.length
                            )
                                handleChangePage(e, page + 1);
                        }}
                        icon={faCaretRight}
                    />
                </div>
            </div>
        </div>
    );
}
