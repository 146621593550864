import React, { useEffect, useState } from "react";
import "./practice.css";
import PageLayout from "../../../components/pageLayout";
import Logo from "../../../components/Logos/logo";
import Tuner from "../../../components/Tuner/Tuner";
import Metronome from "../../../components/Metronome/metronome";
import PracticeTimer from "./practiceTimer";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import PracticePlanViewer from "../../../components/PracticePlan/practicePlanViewer";
import AssignmentToPractice from "./assignmentToPractice";
import StartPractice from "./startPractice";
import { Route, Routes, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faMusic } from "@fortawesome/free-solid-svg-icons";
import PracticePlanGenerator from "../../../components/PracticePlan/practicePlanGenerator";
import HelpSection from "../../../components/HelpSection/helpSection";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import { useAlert } from "../../../components/AlertBanner/alertProvider";
import PracticeDataProvider, { usePracticeData } from "./practiceDataProvider";

const Practice = () => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/practices/active"
    );
    const [activePractice, setActivePractice] = useState(null);
    const navigate = useNavigate();

    const NAV_ITEMS = [
        {
            title: "Practice All Assignments",
            description:
                "Pick from your assignments and practice what you want",
            link: "/dashboard/practice/assignments",
            icon: faMusic,
        },
        {
            title: "View My Practice Plan",
            description: "Check out your AI generated Practice Plan",
            link: "/dashboard/practice/plan-viewer",
            icon: faClipboardCheck,
        },
    ];

    useEffect(() => {
        if (response) {
            setActivePractice(response?.data);
        }
    }, [response]);

    return (
        <PracticeDataProvider>
            <PracticeWSManager>
                <Routes>
                    <Route
                        path="/plan-viewer"
                        element={
                            <PageLayout loading={loading} error={error}>
                                <PracticeNavigator />
                                {activePractice?.planId ? (
                                    <div className="active-practice-container">
                                        <div className="plan-navigator-container">
                                            <PracticePlanViewer
                                                totalTime={
                                                    activePractice?.totalTime
                                                }
                                                planId={activePractice?.planId}
                                            />
                                        </div>
                                        <div className="plan-navigator-viewport-container">
                                            <AssignmentToPractice
                                                planId={activePractice?.planId}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="practice-container">
                                        <PracticePlanGenerator
                                            totalTime={
                                                activePractice?.totalTime
                                            }
                                        />
                                    </div>
                                )}
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/assignments"
                        element={<PracticeAssignmentsPage />}
                    />
                    <Route
                        path="/tuner"
                        element={<PageLayout>Tuner</PageLayout>}
                    />
                    <Route
                        path="/metronome"
                        element={<PageLayout>Metronome</PageLayout>}
                    />
                    <Route
                        path="/"
                        element={
                            <PageLayout loading={loading}>
                                <PracticeNavigator />
                                <div className="practice-container">
                                    {activePractice ? (
                                        <React.Fragment>
                                            <div className="practice-options">
                                                {NAV_ITEMS.map(
                                                    (
                                                        {
                                                            link,
                                                            icon,
                                                            title,
                                                            description,
                                                        },
                                                        i
                                                    ) => {
                                                        return (
                                                            <div
                                                                className="practice-options-item"
                                                                onClick={() =>
                                                                    navigate(
                                                                        link
                                                                    )
                                                                }
                                                                key={i}
                                                            >
                                                                <div className="practice-options-item-icon">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            icon
                                                                        }
                                                                    />
                                                                </div>
                                                                <h2>{title}</h2>
                                                                <h3>
                                                                    {
                                                                        description
                                                                    }
                                                                </h3>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <StartPractice />
                                    )}
                                </div>
                            </PageLayout>
                        }
                    />
                </Routes>
            </PracticeWSManager>
        </PracticeDataProvider>
    );
};

const PracticeNavigator = () => {
    const { practiceData, loading, error } = usePracticeData();

    return (
        <div className="practice-nav-container">
            <PracticeTimer
                practiceId={practiceData?.id}
                totalTime={practiceData?.totalTime}
            />
            <div className="practice-nav-actions">
                <Tuner />
                <Metronome />
            </div>
        </div>
    );
};

const PracticeWSManager = ({ children }) => {
    const { practiceData, loading, error } = usePracticeData();
    const [isEnding, setIsEnding] = useState(false);

    useEffect(() => {
        const ws = new WebSocket(process.env.REACT_APP_WS_URL);

        ws.onopen = () => {
            console.log("WebSocket connected");
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log("Received message:", message);
        };

        const sendTelemetryData = () => {
            const telemetryData = {
                type: "practice_telemetry",
                sessionId: practiceData?.id,
                currentAssignmentId: "current_assignment_id",
                usingPlan: true,
                activePlanSection: 2,
                inputVolume: "current_volume_level",
            };
            ws.send(JSON.stringify(telemetryData));
        };

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = "";

            setIsEnding(true);

            sendTelemetryData({
                action: "session_end_attempt",
                timestamp: Date.now(),
            });
        };

        const handleSessionEnd = () => {
            sendTelemetryData({ action: "session_end", timestamp: Date.now() });
        };

        const telemetryInterval = setInterval(() => {
            sendTelemetryData();
        }, 10000);

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            clearInterval(telemetryInterval);
            window.removeEventListener("beforeunload", handleBeforeUnload);
            ws.close();
        };
    }, []);

    return children;
};

const PracticeAssignmentsPage = () => {
    const [assignments, setAssignments] = useState([]);
    const [assignmentSelected, setAssigmentSelected] = useState(null);
    const [assignmentData, setAssignmentData] = useState({});
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/assignments"
    );
    const { showAlert } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        if (response) {
            setAssignments(response.data);
        }
    }, [response]);

    useEffect(() => {
        function getAssignmentData() {
            fetchWithHeaders("GET", `/assignments/${assignmentSelected}`)
                .then(({ status, data, error }) => {
                    if (error) throw new Error(error);
                    setAssignmentData(data);
                })
                .catch((error) => {
                    console.log(error);
                    showAlert(
                        "There was an error retrieving the assignment: " +
                            error,
                        "error"
                    );
                    setAssignmentData({});
                });
        }
        if (assignmentSelected) {
            getAssignmentData();
        }
    }, [assignmentSelected]);

    return (
        <PageLayout loading={loading} error={error}>
            <PracticeNavigator />
            <ul className="assignments-list">
                {assignments?.map(
                    ({ id, name, description, composer, arranger }, i) => {
                        return (
                            <li
                                key={i}
                                onClick={() => setAssigmentSelected(id)}
                            >
                                <h4>{name}</h4>
                                <p>
                                    {composer}{" "}
                                    {arranger ? " - " + arranger : ""}
                                </p>
                            </li>
                        );
                    }
                )}
            </ul>
            {assignmentData && (
                <div className="iframe-wrapper">
                    <iframe
                        src={assignmentData?.embedLink}
                        width={"100%"}
                        height={"100%"}
                        allowtransparency="true"
                        border="0"
                        style={{
                            borderStyle: "none",
                            borderRadius: "20px",
                            background: "white !important",
                            backgroundColor: "white !important",
                        }}
                        allowFullScreen
                    />
                </div>
            )}
            {assignments.length < 1 && (
                <div className="no-assignments-message">
                    <h1>
                        Nothing to see here!{" "}
                        <HelpSection
                            title={"Assignments"}
                            message={
                                "Once your teacher assigns you a piece, you'll be able to practice them here. Until then, practice whatever you want!"
                            }
                        />
                    </h1>
                    <h2>You don't have any assignments...</h2>
                    <button onClick={() => navigate("/dashboard/practice")}>
                        Back To Practice Home
                    </button>
                </div>
            )}
        </PageLayout>
    );
};

export default Practice;
