import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PermissionsComponent = ({ children, permissions }) => {
    const [hasPermission, setHasPermission] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("auth-token");
        if (!token) {
            navigate("/login");
            return;
        }
        const decoded = jwtDecode(token);

        if (!decoded.permissions) {
            setHasPermission(false);
            return;
        }

        if (!permissions || permissions?.length <= 0) {
            setHasPermission(false);
            return;
        }

        const hasPerm = permissions?.some(
            (mask) => (mask & decoded.permissions) === mask
        );

        setHasPermission(hasPerm);
    }, []);

    if (!hasPermission) {
        return;
    }

    return children;
};

export default PermissionsComponent;
