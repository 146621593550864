import React from "react";

import "./logo.css"

const Logo = ({ width, children }) => {
    return (
        <div className="logo">
            <img src="/logo/Logo.svg" alt="practice pro logo" width={width || "15px"} />
            {children}
        </div>
    );
};

export default Logo;
