import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMusic,
    faBook,
    faGear,
    faBullhorn,
    faHeadset,
    faUser,
    faLineChart,
    faTag,
} from "@fortawesome/free-solid-svg-icons";

import "./navbar.css";
import { UserContext } from "../userProvider";
import { useNavigate } from "react-router-dom";

const STUDENT_LINKS = [
    {
        href: "/dashboard/practice",
        name: "Practice",
        icon: faMusic,
    },
    { href: "/dashboard/journal", name: "Journal", icon: faBook },
];

const TEACHER_LINKS = [
    {
        href: "/dashboard/insights",
        name: "Insights",
        icon: faLineChart,
    },
    { href: "/dashboard/students", name: "Students", icon: faUser },
    {
        href: "/dashboard/pieces",
        name: "Pieces",
        icon: faMusic,
    },
];

const ADMIN_LINKS = [
    {
        href: "/dashboard/insights",
        name: "Insights",
        icon: faLineChart,
    },
    { href: "/dashboard/users", name: "Users", icon: faUser },
];

const APP_LINKS = [
    {
        href: "/dashboard/feedback",
        name: "Feedback",
        icon: faBullhorn,
    },
    {
        href: "/dashboard/support",
        name: "Support",
        icon: faHeadset,
    },
    {
        href: "/dashboard/settings",
        name: "Settings",
        icon: faGear,
    },
];

const LINK_MAPPING = {
    admin: ADMIN_LINKS,
    teacher: TEACHER_LINKS,
    student: STUDENT_LINKS,
};

const Navbar = ({ children }) => {
    const { user, loading } = useContext(UserContext);
    const navigate = useNavigate();
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const initials = loading
        ? "-"
        : user?.fname.slice(0, 1).toUpperCase() +
          user?.lname.slice(0, 1).toUpperCase();

    const links = LINK_MAPPING[user?.userType];

    const name = loading ? "loading..." : user?.fname + " " + user?.lname;
    return (
        <div className="app-container">
            <div className="navbar">
                <img
                    className="logo"
                    src="/app-icon/AppIcon.svg"
                    onClick={() => navigate("/dashboard")}
                />

                <ul>
                    {links?.map(({ href, name, icon }) => {
                        const selected = window.location.href.includes(href);

                        return (
                            <li
                                className={selected ? "nav-link-selected" : ""}
                                key={name}
                                onClick={() => navigate(href)}
                            >
                                <FontAwesomeIcon icon={icon} className="icon" />
                                <h3>{name}</h3>
                            </li>
                        );
                    })}
                    {APP_LINKS?.map(({ href, name, icon }) => {
                        const selected = window.location.href.includes(href);

                        return (
                            <li
                                className={selected ? "nav-link-selected" : ""}
                                key={name}
                                onClick={() => navigate(href)}
                            >
                                <FontAwesomeIcon icon={icon} className="icon" />
                                <h3>{name}</h3>
                            </li>
                        );
                    })}
                </ul>
                <div
                    className="profile"
                    onClick={() => navigate("/dashboard/settings")}
                >
                    <div className="profile-image">
                        <h2>{initials}</h2>
                    </div>
                    <h3>{name}</h3>
                </div>
            </div>
            <div className="mobile-navbar-toggle">
                <img
                    className="logo"
                    src="/app-icon/AppIcon.svg"
                    onClick={() => setMobileNavOpen(!mobileNavOpen)}
                />
            </div>
            <div
                className={
                    mobileNavOpen
                        ? "mobile-navbar-bg mobile-navbar-bg-open"
                        : "mobile-navbar-bg"
                }
                onClick={() => setMobileNavOpen(false)}
            ></div>
            <div
                className={
                    mobileNavOpen
                        ? "mobile-navbar mobile-navbar-open"
                        : "mobile-navbar"
                }
            >
                {mobileNavOpen && (
                    <React.Fragment>
                        <ul>
                            {links.map(({ href, name, icon }) => {
                                const selected =
                                    window.location.href.includes(href);
                                return (
                                    <li
                                        key={name}
                                        className={
                                            selected ? "nav-link-selected" : ""
                                        }
                                        onClick={() => {
                                            navigate(href);
                                            setMobileNavOpen(false);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={icon}
                                            className="icon"
                                        />
                                        <h3>{name}</h3>
                                    </li>
                                );
                            })}
                            {APP_LINKS.map(({ href, name, icon }) => {
                                const selected =
                                    window.location.href.includes(href);
                                return (
                                    <li
                                        key={name}
                                        className={
                                            selected ? "nav-link-selected" : ""
                                        }
                                        onClick={() => {
                                            navigate(href);
                                            setMobileNavOpen(false);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={icon}
                                            className="icon"
                                        />
                                        <h3>{name}</h3>
                                    </li>
                                );
                            })}
                        </ul>
                        <div
                            className="profile"
                            onClick={() => navigate("/dashboard/settings")}
                        >
                            <div className="profile-image">
                                <h2>{initials}</h2>
                            </div>
                            <h3>{name}</h3>
                        </div>
                    </React.Fragment>
                )}
            </div>
            {children}
        </div>
    );
};

export default Navbar;
