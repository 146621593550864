import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import Table from "../Table/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_MAPPING } from "../../lib/iconMapping";

const headCells = [
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
];

const TeacherOptions = ({ onSelect, required = false }) => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/users?userType=teacher"
    );
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        if (response) {
            setTeachers(response.data);
        }
    }, [response]);

    return (
        <div style={{ maxWidth: "30vw", margin: "1rem 0" }}>
            <h4>
                <FontAwesomeIcon
                    icon={ICON_MAPPING["teacher"]}
                    color="var(--accent)"
                    style={{ marginRight: "0.5rem" }}
                />
                Teachers
                {required && <span className="req"> - Required</span>}
            </h4>
            <Table
                rows={teachers.map((s) => {
                    return {
                        id: s.id,
                        name: s.fname + " " + s.lname,
                        iconUrl: s.iconUrl,
                    };
                })}
                headCells={headCells}
                onSelect={onSelect}
                orderByDefault={headCells[0].id}
                emptyTableMessage={"No Teachers"}
            />
        </div>
    );
};

export default TeacherOptions;
