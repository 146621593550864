import React from "react";

const DifficultyOptions = ({ value, onChange, required = false }) => {
    const difficulties = [1, 2, 3, 4, 5];

    return (
        <label>
            <div>
                Difficulty
                {required && <span className="req"> - Required</span>}
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "0.5rem 0",
                }}
            >
                {difficulties.map((difficulty) => {
                    return (
                        <div
                            key={difficulty}
                            onClick={() => {
                                onChange({
                                    target: {
                                        name: "difficulty",
                                        value: difficulty,
                                    },
                                });
                            }}
                            style={{
                                marginRight: "0.5rem",
                                padding: "1rem",
                                backgroundColor:
                                    value === difficulty
                                        ? "var(--accent)"
                                        : "transparent",
                                borderRadius: "100px",
                            }}
                        >
                            <h1
                                style={{
                                    margin: 0,
                                    color:
                                        value === difficulty
                                            ? "var(--primary)"
                                            : "var(--accent)",
                                }}
                            >
                                {difficulty}
                            </h1>
                        </div>
                    );
                })}
            </div>
        </label>
    );
};

export default DifficultyOptions;
