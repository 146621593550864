import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import fetchWithHeaders from "./fetchWithHeaders";
import { jwtDecode } from "jwt-decode";
import LogoWithText from "./Logos/logoWithText";
import Popup from "./Popup/popup";
import { formatMS } from "../lib/format";

const ProtectedPage = ({ children }) => {
    const navigate = useNavigate();
    const [authorized, setAuthorized] = useState(false);
    const [showLogoutWarning, setShowLogoutWarning] = useState(false);
    const [logoutTimerId, setLogoutTimerId] = useState(null);
    const [timeIntervalId, setIntervalId] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        function refreshToken() {
            fetchWithHeaders("POST", "/auth/refresh-token")
                .then(({ status, data, error }) => {
                    if (error)
                        throw new Error("Error refreshing token:", error);
                    localStorage.setItem("auth-token", data?.token);
                    const decodedToken = jwtDecode(data?.token);
                    setAuthorized(true);
                    startLogoutTimer(decodedToken);
                })
                .catch((error) => {
                    console.log(error);
                    logoutHandler();
                });
        }

        function startLogoutTimer(decodedToken) {
            const currentTime = Date.now() / 1000;
            const timeToExpire = decodedToken.exp - currentTime;
            const warningTime = Math.max(0, timeToExpire - 2 * 60);

            const logOutTime = decodedToken.exp * 1000;

            if (logoutTimerId) {
                clearTimeout(logoutTimerId);
            }
            if (timeIntervalId) {
                console.log(timeIntervalId);
                clearInterval(timeIntervalId);
            }
            const timerId = setTimeout(() => {
                setShowLogoutWarning(true);
            }, warningTime * 1000);
            setLogoutTimerId(timerId);

            const intervalId = setInterval(() => {
                const now = Date.now();
                setTimeLeft(Math.ceil(logOutTime - now));
                if (logOutTime - now <= 0) {
                    logoutHandler();
                    clearInterval(intervalId);
                }
            }, 1000);
            setIntervalId(intervalId);

            return () => {
                if (logoutTimerId) {
                    clearTimeout(logoutTimerId);
                }
                if (timeIntervalId) {
                    clearInterval(timeIntervalId);
                }
            };
        }

        // Check authentication status
        const token = localStorage.getItem("auth-token");
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (currentTime > decodedToken.exp - 5 * 60) {
                refreshToken();
            } else if (currentTime < decodedToken.exp) {
                setAuthorized(true);
                startLogoutTimer(decodedToken);
            } else {
                logoutHandler();
            }
        } else {
            logoutHandler();
        }
    }, []);

    const logoutHandler = () => {
        localStorage.clear();
        setAuthorized(false);
        clearInterval(timeIntervalId);
        clearTimeout(logoutTimerId);
        navigate("/login");
    };

    if (authorized) {
        return (
            <div>
                {showLogoutWarning && (
                    <Popup
                        open={showLogoutWarning}
                        setIsOpen={(value) => {
                            window.location.reload();
                        }}
                    >
                        <h3>Your session will expire in: </h3>

                        <h2>{formatMS(timeLeft, true)}</h2>
                        <div className="button-group">
                            <button onClick={() => window.location.reload()}>
                                Stay logged in
                            </button>
                            <button onClick={logoutHandler}>Logout</button>
                        </div>
                    </Popup>
                )}
                {children}
            </div>
        );
    }

    return (
        <div className="page centered">
            <div className="loading-paper">
                <div className="loading-anim"></div>
                <LogoWithText width={160} />
            </div>
        </div>
    );
};

export default ProtectedPage;
