import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";

const IconOptions = ({ value, onChange, required = false }) => {
    const { response, loading, error } = useFetchWithHeaders("GET", "/icons");
    const [icons, setIcons] = useState([]);

    useEffect(() => {
        if (response) {
            setIcons(response.data);
        }
    }, [response]);

    return (
        <label>
            <div>
                Icon
                {required && <span className="req"> - Required</span>}
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "0.5rem 0",
                }}
            >
                {icons.map(({ id, iconUrl, name }) => {
                    return (
                        <img
                            key={id}
                            src={iconUrl}
                            onClick={() => {
                                onChange({
                                    target: {
                                        name: "iconId",
                                        value: id,
                                    },
                                });
                            }}
                            width={"40px"}
                            style={{
                                marginRight: "0.5rem",
                                border:
                                    value === id
                                        ? "2px solid var(--accent)"
                                        : "2px solid transparent",
                                borderRadius: "20px",
                            }}
                        />
                    );
                })}
            </div>
        </label>
    );
};

export default IconOptions;
