import React, { useReducer, useCallback, useState } from "react";
import Field from "../Field/field";
import { useDropzone } from "react-dropzone";
import IconOptions from "../Customization/iconOptions";
import ColorOptions from "../Customization/colorOptions";
import SideDrawer from "../SideDrawer/sideDrawer";
import PickTagscomponent from "../PickTagsComponent/pickTagsComponent";
import HelpSection from "../HelpSection/helpSection";
import { useAlert } from "../AlertBanner/alertProvider";
import { useRefreshKey } from "../RefreshProvider/refreshProvider";

const formReducer = (state, event) => {
    if (event.reset) {
        return {};
    }

    return {
        ...state,
        [event.name]: event.value,
    };
};

function dataURLtoBlob(dataUrl) {
    const parts = dataUrl.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }

    return array.buffer;
}

const AddPieceComponent = ({ state, setState }) => {
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState(null);
    const [newPiece, setNewPiece] = useReducer(formReducer, {
        description: "",
        arranger: "",
        tags: [],
    });
    const { showAlert } = useAlert();
    const { refresh } = useRefreshKey();

    const handleChange = (event) => {
        setNewPiece({
            name: event.target.name,
            value: event.target.value,
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        const fileReader = new FileReader();
        setFilename(acceptedFiles[0]);
        fileReader.onload = () => {
            const data = dataURLtoBlob(fileReader.result);
            setFile(data);
        };
        fileReader.readAsDataURL(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
    });

    async function createPiece(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append(
            "fileData",
            new Blob([file], { type: "application/octet-stream" }),
            filename
        );
        formData.append("name", newPiece.name);
        formData.append("notes", newPiece.description);
        formData.append("composer", newPiece.composer);
        formData.append("arranger", newPiece.arranger);
        formData.append("colorId", newPiece.colorId);
        formData.append("iconId", newPiece.iconId);
        formData.append("tags", newPiece.tags?.join(","));

        try {
            await fetch(`${process.env.REACT_APP_API_URL}/pieces`, {
                method: "POST",
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("auth-token"),
                },
                body: formData,
            });
            showAlert("Successfully added piece", "success");
            refresh();
            setState(false);
        } catch (error) {
            console.log(error);
            showAlert("There was an error adding the piece: " + error, "error");
        }
    }

    return (
        <SideDrawer isOpen={state} setIsOpen={setState}>
            <div className="summary">
                <h2>Add A New Piece</h2>
            </div>
            <form onSubmit={createPiece}>
                <Field
                    name={"name"}
                    label={"Title"}
                    value={newPiece.name}
                    onChange={handleChange}
                    required
                />
                <Field
                    name={"composer"}
                    label={"Composer"}
                    value={newPiece.composer}
                    onChange={handleChange}
                    required
                />
                <Field
                    name={"arranger"}
                    label={"Arranger"}
                    value={newPiece.arranger}
                    onChange={handleChange}
                />
                <Field
                    name={"description"}
                    label={"Description"}
                    value={newPiece.description}
                    onChange={handleChange}
                />
                <label
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                    }}
                >
                    File
                    <HelpSection
                        title={"Piece File"}
                        message={
                            "Students will be able to view this file once this piece is assigned to them. Be sure not to upload anything sensitive."
                        }
                    />
                </label>
                {file ? (
                    <div onClick={() => setFile(null)} className="file-added">
                        <div className="tag">{filename.name}</div>
                    </div>
                ) : (
                    <div {...getRootProps()} className="file-dropzone">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop a file here ...</p>
                        ) : (
                            <p>
                                Drag 'n' drop a file here, or click to select a
                                file
                            </p>
                        )}
                    </div>
                )}
                <IconOptions
                    value={newPiece?.iconId}
                    onChange={(e) =>
                        setNewPiece({
                            name: "iconId",
                            value: e.target.value,
                        })
                    }
                    required
                />
                <ColorOptions
                    value={newPiece?.colorId}
                    onChange={(e) =>
                        setNewPiece({
                            name: "colorId",
                            value: e.target.value,
                        })
                    }
                    required
                />
                <PickTagscomponent
                    value={newPiece?.tags}
                    onChange={(tags) =>
                        setNewPiece({ name: "tags", value: tags })
                    }
                />
                <div className="button-group">
                    <button type="submit">Add</button>
                    <button type="button" onClick={() => setState(!state)}>
                        Cancel
                    </button>
                </div>
            </form>
        </SideDrawer>
    );
};

export default AddPieceComponent;
