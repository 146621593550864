import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import Table from "../Table/table";

const headCells = [
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
];

const StudentOptions = ({ onSelect, required = false }) => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/users?userType=student`
    );
    const [students, setStudents] = useState([]);

    useEffect(() => {
        if (response) {
            setStudents(response.data);
        }
    }, [response]);

    if (error) {
        return (
            <div>
                <p className="invalid">
                    There was an error loading this content
                </p>
            </div>
        );
    }
    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <label>
                <div>
                    Students
                    {required && <span className="req"> - Required</span>}
                </div>
            </label>
            <Table
                rows={students.map((s) => {
                    return {
                        id: s.id,
                        name: s.fname + " " + s.lname,
                        iconUrl: s.iconUrl,
                    };
                })}
                bulkActions={[]}
                headCells={headCells}
                onSelect={onSelect}
                orderByDefault={headCells[0].id}
                emptyTableMessage={"No Students"}
            />
        </React.Fragment>
    );
};

export default StudentOptions;
