import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faTag } from "@fortawesome/free-solid-svg-icons";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";

import "./pickTags.css";
import HelpSection from "../HelpSection/helpSection";

const PickTagscomponent = ({ value, onChange }) => {
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setDebouncedSearchValue(search);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    function handleChange(e) {
        setSearch(e.target.value);
    }

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/tags?search=${debouncedSearchValue}&limit=4`
    );

    const [isAdding, setIsAdding] = useState(false);

    function addTag(t) {
        if (value.includes(t)) {
            return;
        }
        const newTags = [...value];
        newTags.push(t);
        onChange(newTags);
    }

    function removeTag(t) {
        const newTags = value?.filter((tag) => tag !== t);
        onChange(newTags);
    }

    useEffect(() => {
        if (response) {
            setSearchResult(response.data);
        }
    }, [response]);

    return (
        <div>
            <h4
                style={{
                    display: "flex",
                    gap: "1rem",
                    alignContent: "center",
                    marginBottom: "0.5rem",
                }}
                onClick={() => {
                    setIsAdding(!isAdding);
                }}
            >
                Tags
                <FontAwesomeIcon
                    icon={faTag}
                    className="tag-icon"
                    fontSize={"large"}
                />
                <HelpSection
                    title="Tags"
                    message="Add tags to keep your workspace organized. You can filter by tags throughout the app. Search for tags below or create a new one!"
                />
            </h4>
            <div className="tags-list">
                {value?.map((t, i) => {
                    const selected = value?.includes(t);
                    return (
                        <div
                            className="filter"
                            key={i}
                            onClick={() =>
                                selected ? removeTag(t) : addTag(t)
                            }
                        >
                            {t}
                        </div>
                    );
                })}
            </div>
            <div
                className={
                    isAdding
                        ? "tag-search-container"
                        : "tag-search-container tag-search-container-hidden"
                }
            >
                <label>
                    Search or Create Tags
                    <input
                        name="search"
                        value={search}
                        onChange={handleChange}
                    />
                </label>

                {searchResult?.length > 0 && (
                    <div className="tag-search-results-container">
                        <ul>
                            {searchResult?.map((t, i) => {
                                const selected = value?.includes(t);

                                return (
                                    <li
                                        key={i}
                                        onClick={() =>
                                            selected ? removeTag(t) : addTag(t)
                                        }
                                    >
                                        {selected && (
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="tag-checked"
                                            />
                                        )}
                                        {t}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {search.length > 0 && !searchResult.includes(search) && (
                    <button
                        onClick={() => {
                            addTag(search);
                            setSearch("");
                        }}
                        type="button"
                    >
                        Create new tag "{search}"
                    </button>
                )}
            </div>
        </div>
    );
};

export default PickTagscomponent;
