import React from "react";

import "./pageFooter.css";

const PageFooter = () => {
    return (
        <div className="page-footer-container">
            <div className="page-footer">
                <h4>Thank you for using practice pro!</h4>
                <hr />
                <h4>
                    <a
                        href="https://app.getpracticepro.com/app/dashboard/support"
                        style={{ color: "var(--accent)", fontWeight: "700" }}
                    >
                        support@getpracticepro.com
                    </a>
                </h4>
                <hr />
                <h4>
                    Copyright 2024{" "}
                    <a
                        href="https://getpracticepro.com"
                        style={{ color: "var(--accent)", fontWeight: "700" }}
                    >
                        Practice Pro Systems LLC
                    </a>
                </h4>
            </div>
        </div>
    );
};

export default PageFooter;
