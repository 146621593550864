import React from "react";

const LogoWithText = ({ width }) => {
    return (
        <img
            src="/logo-with-text/Transparent@3x.png"
            alt="practice pro logo"
            width={width || "40px"}
        />
    );
};

export default LogoWithText;
