import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLayout from "../../../components/pageLayout";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import AssignPiecesComponent from "../../../components/Piece/assignPiecesComponent";
import Table from "../../../components/Table/table";
import Popup from "../../../components/Popup/popup";

import "./pieces.css";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import { useAlert } from "../../../components/AlertBanner/alertProvider";
import { useRefreshKey } from "../../../components/RefreshProvider/refreshProvider";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import EditPieceComponent from "../../../components/editPieceComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const PiecePage = () => {
    const { pieceId } = useParams();
    const [pieceData, setPieceData] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const assign = searchParams.get("assign") === "true";
    const [isAssigning, setIsAssigning] = useState(assign);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { showAlert } = useAlert();
    const { refreshKey, refresh } = useRefreshKey();
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/pieces/${pieceId}?refreshKey=${refreshKey}`
    );

    const navigate = useNavigate();

    async function archivePiece() {
        fetchWithHeaders("PATCH", "/pieces", { pieces: [pieceId] })
            .then(({ status, data, error }) => {
                if (error) throw new Error(error);
                showAlert("Successfully archived piece", "success");
                navigate("/dashboard/pieces");
            })
            .catch((error) => {
                console.log(error);
                showAlert(
                    "There was an error archiving this piece: " + error,
                    "error"
                );
            });
    }

    useEffect(() => {
        if (response) {
            setPieceData(response.data);
        }
    }, [response]);

    return (
        <PageLayout loading={loading} error={error}>
            <div className="piece-header" onClick={() => setIsEditing(true)}>
                <img src={pieceData?.iconUrl} className="instrument-icon" />
                <h2>{pieceData?.name}</h2>
                <PermissionsComponent permissions={[Permission.WRITE_PIECE]}>
                    <FontAwesomeIcon icon={faEdit} color="var(--accent)" />
                </PermissionsComponent>
            </div>
            <div className="tags-container" onClick={() => setIsEditing(true)}>
                {pieceData?.tags?.map((t) => (
                    <div key={t} className="tag">
                        {t}
                    </div>
                ))}
            </div>
            <section>
                <h3>Piece Info</h3>
                <p>Description: {pieceData?.description || "None"}</p>
                <p>Composer: {pieceData?.composer}</p>
                <p>Arranger: {pieceData?.arranger || "None"}</p>
                <div className="button-group">
                    <button onClick={() => setIsAssigning(true)}>Assign</button>
                    <button onClick={() => setIsArchiving(true)}>
                        Archive
                    </button>
                </div>
            </section>
            {/* <PermissionsComponent permissions={[Permission.READ_ASSIGNMENT]}>
                <section>
                    <h3>Assigned To</h3>
                    <Table
                        rows={[]}
                        bulkActions={[]}
                        emptyTableMessage={"Not Assigned"}
                        headCells={[]}
                    />
                </section>
            </PermissionsComponent> */}
            <section>
                <iframe
                    src={pieceData?.embedLink}
                    width={"100%"}
                    height={500}
                />
            </section>
            <PermissionsComponent permissions={[Permission.WRITE_ASSIGNMENT]}>
                <AssignPiecesComponent
                    pieces={[pieceId]}
                    state={isAssigning}
                    setState={(value) => {
                        setIsAssigning(value);
                        setSearchParams("");
                    }}
                />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.WRITE_PIECE]}>
                <EditPieceComponent
                    id={pieceId}
                    isOpen={isEditing}
                    setIsOpen={setIsEditing}
                />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.WRITE_PIECE]}>
                <Popup open={isArchiving} setIsOpen={setIsArchiving}>
                    <h2>Are you sure you want to archive this piece?</h2>
                    <p>
                        Students will need to look in their archived assignments
                        to view this piece
                    </p>
                    <button onClick={archivePiece}>Yes, Archive</button>
                </Popup>
            </PermissionsComponent>
        </PageLayout>
    );
};

export default PiecePage;
