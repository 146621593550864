import React, { useEffect, useState } from "react";
import PageLayout from "../../../components/pageLayout";
import Logo from "../../../components/Logos/logo";
import StudentSubscriptionDetails from "./studentSubscriptionDetails";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import Table from "../../../components/Table/table";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import Popup from "../../../components/Popup/popup";
import { useSearchParams } from "react-router-dom";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import AddUserDrawer from "../../../components/addUserDrawer";
import UserDetails from "../Users/userDetails";
import HelpSection from "../../../components/HelpSection/helpSection";
import { useRefreshKey } from "../../../components/RefreshProvider/refreshProvider";
import { useAlert } from "../../../components/AlertBanner/alertProvider";
import { formatDate } from "../../../lib/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_MAPPING } from "../../../lib/iconMapping";

const headCells = [
    {
        id: "fname",
        numeric: false,
        label: "First Name",
        style: {
            width: "1%",
            whiteSpace: "nowrap",
            padding: "0 2rem",
        },
    },
    {
        id: "lname",
        numeric: false,
        label: "Last Name",
        style: {
            width: "1%",
            whiteSpace: "nowrap",
            padding: "0 2rem",
        },
    },
    {
        id: "createdAt",
        label: "Created At",
        numeric: false,
        formatter: formatDate,
        responsive: true,
        style: {
            width: "1%",
            whiteSpace: "nowrap",
            padding: "0 2rem",
        },
    },
    {
        id: "tags",
        numeric: false,
        label: "Tags",
        formatter: (tags) => {
            return (
                <div className="table-tags-container">
                    {tags?.map((tag, i) => {
                        if (i > 3) {
                            return;
                        }
                        return (
                            <div key={i} className="tag">
                                {tag}
                            </div>
                        );
                    })}
                </div>
            );
        },
        responsive: true,
    },
];

const Students = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [studentSelected, setStudentSelected] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [students, setStudents] = useState([]);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [tags, setTags] = useState("");
    const { refreshKey, refresh } = useRefreshKey();
    const { showAlert } = useAlert();

    function archiveStudents() {
        fetchWithHeaders("PATCH", `/users`, { userIds: selected })
            .then(({ status, data, error }) => {
                if (error) throw new Error(error);
                showAlert("Successfully archived users", "success");
                refresh();
                setIsRemoving(false);
            })
            .catch((error) => {
                console.log(error);
                showAlert(
                    "There was an error archiving the users: " + error,
                    "error"
                );
            });
    }

    function handleIsAdding() {
        setIsAdding(true);
    }

    function handleIsArchiving() {
        setIsRemoving(true);
    }

    function viewDetails(id) {
        setSearchParams(`studentId=${id}`);
        setStudentSelected(id);
        setIsOpen(true);
    }

    const bulkActions = [
        {
            label: "Add",
            action: handleIsAdding,
            requiresSelected: false,
        },
        {
            label: "Archive",
            action: handleIsArchiving,
            requiresSelected: true,
        },
        {
            label: (
                <HelpSection
                    title={"Students Manager"}
                    message={
                        "Add or remove students here. Click a student to see more details. You can also filter by tags!"
                    }
                />
            ),
            action: () => {},
            requiresSelected: false,
        },
    ];

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/users?userType=student&tags=${tags}&refreshKey=${refreshKey}`
    );

    useEffect(() => {
        if (response) {
            setStudents(response.data);
        }
    }, [response]);

    if (loading) {
        return <PageLayout loading={true} />;
    }

    if (error) {
        return <PageLayout error={true} />;
    }

    return (
        <PageLayout
            isOpen={isOpen && studentSelected}
            setIsOpen={setIsOpen}
            sideComponent={
                <PermissionsComponent permissions={[Permission.READ_STUDENT]}>
                    {studentSelected && <UserDetails id={studentSelected} />}
                </PermissionsComponent>
            }
        >
            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <FontAwesomeIcon
                    icon={ICON_MAPPING["student"]}
                    color="var(--accent)"
                    fontSize={"large"}
                />
                <h1 style={{ marginBottom: "0" }}>Students</h1>
            </div>
            <PermissionsComponent permissions={[Permission.READ_SUBSCRIPTION]}>
                <StudentSubscriptionDetails />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.READ_STUDENT]}>
                <Table
                    rows={students}
                    headCells={headCells}
                    onFilterTags={setTags}
                    orderByDefault={headCells[0].id}
                    bulkActions={bulkActions}
                    onSelect={(selected) => {
                        setSelected(selected);
                    }}
                    handleClick={viewDetails}
                />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.WRITE_STUDENT]}>
                <AddUserDrawer isOpen={isAdding} setIsOpen={setIsAdding} />
            </PermissionsComponent>
            <PermissionsComponent permissions={[Permission.DELETE_STUDENT]}>
                <Popup open={isRemoving} setIsOpen={setIsRemoving}>
                    <h2>
                        Are you sure you want to archive {selected?.length}{" "}
                        student
                        {selected?.length > 1 ? "s" : ""}?
                    </h2>
                    <p>
                        Any pending invites will be resended and they will not
                        be able to access their account. Use this function to
                        free up student slots.
                    </p>
                    <button onClick={archiveStudents}>
                        Yes, Archive Students
                    </button>
                </Popup>
            </PermissionsComponent>
        </PageLayout>
    );
};

export default Students;
