import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";

const ColorOptions = ({ value, onChange, required = false }) => {
    const { response, loading, error } = useFetchWithHeaders("GET", "/colors");
    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (response) {
            setColors(response.data);
        }
    }, [response]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <p className="invalid">There was an error loading this content</p>
        );
    }
    return (
        <label>
            <div>
                Color
                {required && <span className="req"> - Required</span>}
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "0.5rem 0",
                }}
            >
                {colors.map(({ id, hexCode, name }) => {
                    return (
                        <div
                            key={id}
                            onClick={() => {
                                onChange({
                                    target: {
                                        name: "colorId",
                                        value: id,
                                    },
                                });
                            }}
                            style={{
                                marginRight: "0.5rem",
                                border:
                                    value === id
                                        ? "2px solid var(--accent)"
                                        : "2px solid transparent",
                                borderRadius: "20px",
                                backgroundColor: hexCode,
                                width: "40px",
                                height: "40px",
                            }}
                        />
                    );
                })}
            </div>
        </label>
    );
};

export default ColorOptions;
