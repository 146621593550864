import React from "react";

import "./popup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const Popup = ({ open, setIsOpen, onBack, children }) => {
    return (
        <div>
            <div
                className={
                    open
                        ? "popup-background"
                        : "popup-background popup-background-closed"
                }
                onClick={() => {
                    if (setIsOpen) setIsOpen(false);
                }}
            />
            <div className={open ? "paper popup" : "paper popup popup-closed"}>
                <button
                    className="popup-back"
                    type="button"
                    onClick={() => {
                        if (setIsOpen) setIsOpen(false);
                        if (onBack) onBack();
                    }}
                >
                    <FontAwesomeIcon icon={faCaretLeft} />
                </button>
                {children}
            </div>
        </div>
    );
};

export default Popup;
