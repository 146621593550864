import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import Table from "../Table/table";
import { formatDate, formatMS } from "../../lib/format";
import fetchWithHeaders from "../fetchWithHeaders";
import Popup from "../Popup/popup";
import HelpSection from "../HelpSection/helpSection";

const headCells = [
    {
        id: "date",
        numeric: false,
        label: "Date",
        formatter: formatDate,
    },
    {
        id: "totalTime",
        numeric: false,
        label: "Practice Time",
        formatter: formatMS,
    },
];

const PracticeTable = ({ onSelect }) => {
    const [practices, setPractices] = useState([]);
    const [isArchiving, setIsArchiving] = useState(false);
    const [pracSelected, setSelected] = useState([]);

    function archivePractices() {
        try {
            const { status, data, error } = fetchWithHeaders(
                "PATCH",
                "/practices",
                { practices: [...pracSelected] }
            );

            if (error) throw new Error(error);
            window.location.reload(false);
        } catch (error) {
            console.log(error);
            alert(error);
        } finally {
            setIsArchiving(false);
        }
    }

    function handleIsArchiving(selected) {
        setSelected(selected);
        setIsArchiving(true);
    }

    const bulkActions = [
        {
            label: "Archive",
            action: handleIsArchiving,
            requiresSelected: true,
        },
        {
            label: (
                <HelpSection
                    title="Practice Manager"
                    message={
                        "General data about your practice sessions appear here. Visit the practice page to start practicing!"
                    }
                />
            ),
            action: () => {},
            requiresSelected: false,
        },
    ];

    let url = `/practices`;
    const { response, loading, error } = useFetchWithHeaders("GET", url);

    useEffect(() => {
        if (response) {
            setPractices(response.data);
        }
    }, [response]);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim" />
            </div>
        );
    }

    return (
        <section>
            <h3>Practice Sessions</h3>
            <p>All of your practice sessions</p>
            <Table
                rows={practices}
                headCells={headCells}
                orderByDefault={headCells[0].id}
                orderDefault="desc"
                bulkActions={bulkActions}
                emptyTableMessage={
                    "No practices. Press start to begin practicing!"
                }
            />
            <Popup open={isArchiving} setIsOpen={setIsArchiving}>
                <h2>Are you sure you want to archive these practices?</h2>
                <p>
                    They will no longer be used to calculate your practice
                    performance
                </p>
                <button onClick={() => archivePractices()}>Archive</button>
            </Popup>
        </section>
    );
};

export default PracticeTable;
