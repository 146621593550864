import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import AssignPiecesComponent from "./assignPiecesComponent";
import { useNavigate } from "react-router-dom";

const PieceDetails = ({ id }) => {
    let { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/pieces/${id}`
    );
    const [piece, setPiece] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (response) {
            setPiece(response.data);
        }
    }, [response]);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim" />
            </div>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <h2>{piece?.name}</h2>
            <iframe
                className="responsive"
                src={piece?.embedLink}
                height={500}
            />
            <div className="button-group">
                <button
                    onClick={() =>
                        navigate(`/dashboard/pieces/${id}?assign=true`)
                    }
                >
                    Assign This Piece
                </button>
                <button onClick={() => navigate(`/dashboard/pieces/${id}`)}>
                    More Details
                </button>
            </div>
        </div>
    );
};

export default PieceDetails;
