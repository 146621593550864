import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import ProgressBar from "../../../components/ProgressBar/progressBar";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import HelpSection from "../../../components/HelpSection/helpSection";

const StudentSubscriptionDetails = () => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/subscriptions"
    );
    const [subscriptionDetails, setSubscriptionDetails] = useState({});

    useEffect(() => {
        if (response) {
            setSubscriptionDetails(response.data);
        }
    }, [response]);

    const handleCreatePortalSession = async () => {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/stripe/create-portal-session"
            );

            if (data.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                console.error("Error: No redirect URL found in response");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="section">
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "2rem",
                    marginBottom: "1rem",
                }}
            >
                <div>
                    <h1 style={{ fontSize: "40px", color: "var(--secondary)" }}>
                        {subscriptionDetails.studentCount || "0"}/
                        {subscriptionDetails.studentLimit || "-"}
                    </h1>
                    <div style={{ display: "flex", gap: "0.5rem"}}>
                        <h2>Students</h2>
                        <HelpSection
                            title="Membership"
                            message={`Your membership includes ${subscriptionDetails.studentLimit} student accounts, upgrade for more!`}
                        />
                    </div>
                </div>
                <PermissionsComponent
                    permissions={[Permission.WRITE_SUBSCRIPTION]}
                >
                    <div>
                        <button onClick={handleCreatePortalSession}>
                            See available plans
                        </button>
                    </div>
                </PermissionsComponent>
            </div>
            <ProgressBar
                progress={subscriptionDetails.studentCount || 0}
                totalAmt={subscriptionDetails.studentLimit || 1}
            />
        </div>
    );
};

export default StudentSubscriptionDetails;
