import React from "react";
import SideDrawer from "./SideDrawer/sideDrawer";
import LogoWithText from "./Logos/logoWithText";
import AlertBanner from "./AlertBanner/alertBanner";
import PageFooter from "./PageFooter/pageFooter";
import UpdateHandler from "./UpdateHandler/updateHandler";

const PageLayout = ({
    children,
    loading,
    error,
    isOpen,
    setIsOpen,
    sideComponent,
}) => {
    if (loading) {
        return (
            <div className="main-block-container">
                <div className="main-block">
                    <div className="loading-paper">
                        <div className="loading-anim"></div>
                        <LogoWithText width={150} />
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="main-block-container">
                <div className="main-block">
                    <div className="paper">
                        <p className="invalid">
                            There was an error loading this content
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="main-block-container">
            <div className="main-block">
                <AlertBanner />
                <UpdateHandler />
                {children}
                <PageFooter />
            </div>

            <SideDrawer
                isOpen={isOpen}
                setIsOpen={(value) => {
                    if (setIsOpen) setIsOpen(value);
                }}
            >
                {sideComponent}
            </SideDrawer>
        </div>
    );
};

export default PageLayout;
