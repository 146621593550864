import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTabs from "../../components/Tabs/useTabs";
import "./membership.css";
import Switch from "../../components/Switch/switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const USER_TYPES = [
    { discriminator: "teacher", label: "teacher" },
    { discriminator: "school", label: "school" },
];

const PLAN_TYPES = ["Monthly", "Annual"];

const Membership = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [tab, Tabs] = useTabs(USER_TYPES, onTabChange);
    const [planType, setPlanType] = useState(PLAN_TYPES[0]);

    function onTabChange(index) {
        handleFilterPlans(plans, index, planType);
    }

    function handleFilterPlans(array, index, planType) {
        const filtered = array.filter(
            ({ userType, cadence }) =>
                userType === USER_TYPES[index].discriminator &&
                cadence === planType
        );
        setFilteredPlans(filtered);
        return;
    }

    useEffect(() => {
        async function getPlans() {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/stripe/plans"
                );
                const body = await response.json();
                setPlans(body);
                handleFilterPlans(body, tab, planType);
            } catch (error) {
                console.log(error);
            }
        }
        getPlans();
    }, []);

    function handleSwitch(event) {
        const newPlanType = event.target.checked
            ? PLAN_TYPES[1]
            : PLAN_TYPES[0];
        handleFilterPlans(plans, tab, newPlanType);
        setPlanType(newPlanType);
    }

    return (
        <div className="page centered">
            <div>
                <h3 style={{ textAlign: "center" }}>PracticePro</h3>
                <h2 style={{ textAlign: "center" }}>Membership Plans</h2>
                <Switch
                    leftOption={"Billed Monthly"}
                    rightOption={"Billed Annually"}
                    checked={planType === PLAN_TYPES[1]}
                    onChange={handleSwitch}
                />
                <Tabs />
                <div className="membership-plans">
                    {filteredPlans?.map(
                        ({ freeTrial, id, priceId, name, price, cadence }) => {
                            return (
                                <div
                                    className={`membership-plan ${
                                        freeTrial ? "best" : ""
                                    }`}
                                    key={id}
                                    style={{ padding: "2rem" }}
                                    onClick={() =>
                                        navigate(`/checkout?priceId=${priceId}`)
                                    }
                                >
                                    {freeTrial && (
                                        <div className={"best"}>
                                            <h3 style={{ color: "white" }}>
                                                {freeTrial} Day Free Trial
                                            </h3>
                                        </div>
                                    )}
                                    <h2>{name}</h2>
                                    <ul>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="icon"
                                            />
                                            3GB of Piece PDF storage
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="icon"
                                            />
                                            Detailed Practice Insights
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="icon"
                                            />
                                            24/7 Tech Support Team
                                        </li>
                                    </ul>

                                    <h2 className="price">
                                        ${Math.fround(price / 100).toFixed(0)}
                                        <span className="cadence">
                                            /
                                            {cadence === "Monthly"
                                                ? "month"
                                                : "year"}
                                        </span>
                                    </h2>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
            <p>
                Already a member? <a href="/app/login">Login Here</a>
            </p>
        </div>
    );
};

export default Membership;
