import React, { useEffect, useState } from "react";
import fetchWithHeaders from "../fetchWithHeaders";
import { formatMS } from "../../lib/format";

import "./planViewer.css";

const PracticePlanViewer = ({ totalTime, planId }) => {
    const [planDetails, setPlanDetails] = useState(null);

    useEffect(() => {
        async function getPlanDetails() {
            try {
                const { status, data, error } = await fetchWithHeaders(
                    "GET",
                    `/plans/${planId}`
                );
                if (error) throw new Error(error);
                setPlanDetails(data);
            } catch (error) {
                console.log(error);
            }
        }
        if (planId) {
            getPlanDetails();
        }
    }, [planId]);

    function calculateProgressBar() {
        if (!planDetails) return "0%";

        const maxHeight = 100;

        const sectionAmt = planDetails.sections.length;
        let activeSections = 0;

        planDetails.sections.map((s) => {
            if (s.startedTime) {
                activeSections++;
            } else {
                return;
            }
        });

        const height =
            (maxHeight * totalTime) / (planDetails.duration * 60 * 1000);

        return Math.min(height, maxHeight) + "%";
    }

    return (
        <div className="plan-viewer">
            <h2>Practice Plan</h2>
            <h3>{formatMS(planDetails?.duration * 60 * 1000, true)}</h3>

            <ul>
                <div className="progress-bar-container">
                    <div
                        className="progress-bar"
                        style={{ height: calculateProgressBar() }}
                    />
                </div>
                {planDetails?.sections?.map((section, i) => {
                    return (
                        <li key={i}>
                            <div
                                className={
                                    section.startedTime ? "node active" : "node"
                                }
                            />
                            <h4>{section.name}</h4>
                            <h3>
                                {formatMS(section.duration * 60 * 1000, true)}
                            </h3>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default PracticePlanViewer;
