import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./journal.css";
import PageSummary from "../../../components/PageSummary/pageSummary";
import PageLayout from "../../../components/pageLayout";
import PracticeTable from "../../../components/Practice/practiceTable";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import Table from "../../../components/Table/table";
import SideDrawer from "../../../components/SideDrawer/sideDrawer";
import AssignmentDetails from "../../../components/Assignment/assignmentDetails";
import HelpSection from "../../../components/HelpSection/helpSection";

const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const Journal = () => {
    return (
        <PageLayout disableSideComponent>
            <h3>PracticePro</h3>
            <h2>Journal</h2>
            <PageSummary>
                <h1>Happy {Days[new Date().getDay()]}!</h1>
                <h3>Welcome to Practice Pro</h3>
            </PageSummary>

            <PracticeTable />
            <MyAssignments />
        </PageLayout>
    );
};

const MyAssignments = () => {
    const headCells = [
        {
            id: "name",
            numeric: false,
            label: "Name",
        },
        {
            id: "composer",
            numeric: false,
            label: "Composer",
        },
        {
            id: "arranger",
            numeric: false,
            label: "Arranger",
        },
    ];
    const [assignments, setAssignments] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [detailsId, setDetailsId] = useState(null);
    const navigate = useNavigate();

    function createPlan(selected) {
        navigate("/dashboard/practice");
    }
    function handleDetails(selected) {
        setIsOpen(true);
        setDetailsId(selected);
    }

    const bulkActions = [
        {
            label: "Create Plan",
            action: createPlan,
            requiresSelected: true,
        },
        {
            label: (
                <HelpSection
                    title="Assignment Viewer"
                    message={
                        'When your teachers assign you pieces, they\'ll appear here. Visit the practice page or click "Create Plan" to generate a custom practice plan with your assignments!'
                    }
                />
            ),
            action: () => {},
            requiresSelected: false,
        },
    ];

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/assignments"
    );

    useEffect(() => {
        if (response) {
            setAssignments(response.data);
        }
    }, [response]);

    return (
        <section>
            <h3>Assignments</h3>
            <p>All of your assignments</p>
            <Table
                headCells={headCells}
                orderByDefault={headCells[0].id}
                rows={assignments}
                bulkActions={bulkActions}
                emptyTableMessage={"No Assignments"}
                handleClick={handleDetails}
            />
            <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
                {detailsId && <AssignmentDetails id={detailsId} />}
            </SideDrawer>
        </section>
    );
};

export default Journal;
