import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/login";
import Checkout from "./pages/Checkout/checkout";
import Membership from "./pages/Membership/membership";
import Dashboard from "./pages/Dashboard/dashboard";
import ProtectedPage from "./components/protectedPage";
import NotFound from "./pages/NotFound/notFound";
import Feedback from "./pages/Dashboard/Feedback/feedback";
import Support from "./pages/Dashboard/Support/support";
import ForgotPass from "./pages/ForgotPass/forgotPass";
import UserProvider from "./components/userProvider";
import Navbar from "./components/Navbar/navbar";
import VerifyEmail from "./pages/VerifyEmail/verifyEmail";
import StudentInvite from "./pages/StudentInvite/studentInvite";
import CheckoutSuccess from "./pages/CheckoutSuccess/CheckoutSuccess";
import EndedMembership from "./pages/Dashboard/EndedMembership/endedMembership";
import { AlertProvider } from "./components/AlertBanner/alertProvider";
import { RefreshProvider } from "./components/RefreshProvider/refreshProvider";

function App() {
    return (
        <BrowserRouter basename="/app">
            <Routes>
                <Route path={"/"} element={<Navigate to={"/login"} />} />
                <Route path={"/login"} element={<Login />} />
                <Route path={"/forgot-password"} element={<ForgotPass />} />
                <Route path={"/verify-email"} element={<VerifyEmail />} />
                <Route path={"/student-invite"} element={<StudentInvite />} />
                <Route
                    path={"/invalid-membership"}
                    element={
                        <ProtectedPage>
                            <RefreshProvider>
                                <UserProvider>
                                    <EndedMembership />
                                </UserProvider>
                            </RefreshProvider>
                        </ProtectedPage>
                    }
                />
                <Route path={"/checkout"} element={<Checkout />} />
                <Route
                    path={"/checkout-success"}
                    element={<CheckoutSuccess />}
                />
                <Route path={"/membership"} element={<Membership />} />
                <Route
                    path={"/dashboard/*"}
                    element={
                        <ProtectedPage>
                            <AlertProvider>
                                <RefreshProvider>
                                    <UserProvider>
                                        <Navbar>
                                            <Dashboard />
                                        </Navbar>
                                    </UserProvider>
                                </RefreshProvider>
                            </AlertProvider>
                        </ProtectedPage>
                    }
                />
                <Route path="/support" element={<Support />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
