import React, { useState } from "react";
import fetchWithHeaders from "../fetchWithHeaders";
import DifficultyOptions from "../Assignment/difficultyOptions";
import StudentOptions from "../Student/studentOptions";
import { useAlert } from "../AlertBanner/alertProvider";
import { useRefreshKey } from "../RefreshProvider/refreshProvider";
import SideDrawer from "../SideDrawer/sideDrawer";

const AssignPiecesComponent = ({ state, setState, pieces }) => {
    const [difficulty, setDifficulty] = useState(1);
    const [studentsToAssign, setStudentsToAssign] = useState([]);
    const { showAlert } = useAlert();
    const { refreshKey, refresh } = useRefreshKey();

    async function assignPieces() {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                "/assignments/bulkAssign",
                {
                    pieces: pieces,
                    students: studentsToAssign,
                    difficulty: difficulty,
                }
            );
            if (error) throw new Error(error);
            showAlert("Successfully assigned pieces", "success");
            refresh();
            setState(false);
        } catch (error) {
            console.log(error);
            showAlert(
                "There was an error assigning the pieces: " + error,
                "error"
            );
        }
    }

    return (
        <SideDrawer isOpen={state} setIsOpen={setState}>
            <h2>
                Assign {pieces.length} piece{pieces.length > 1 ? "s" : ""}
            </h2>

            <DifficultyOptions
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                required
            />
            <StudentOptions
                onSelect={(selected) => {
                    setStudentsToAssign(selected);
                }}
                required
            />
            <div className="button-group">
                <button onClick={assignPieces}>Assign</button>
            </div>
        </SideDrawer>
    );
};

export default AssignPiecesComponent;
