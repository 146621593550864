const Permission = {
    READ_STUDENT: 1 << 0,
    WRITE_STUDENT: 1 << 1,
    DELETE_STUDENT: 1 << 2,
    READ_ASSIGNMENT: 1 << 3,
    WRITE_ASSIGNMENT: 1 << 4,
    DELETE_ASSIGNMENT: 1 << 5,
    READ_MEMBER_GROUP: 1 << 6,
    WRITE_MEMBER_GROUP: 1 << 7,
    READ_PIECE: 1 << 9,
    WRITE_PIECE: 1 << 10,
    DELETE_PIECE: 1 << 11,
    READ_ADMIN: 1 << 12,
    WRITE_ADMIN: 1 << 13,
    DELETE_ADMIN: 1 << 14,
    READ_INSTITUTION: 1 << 15,
    WRITE_INSTITUTION: 1 << 16,
    READ_TEACHER: 1 << 17,
    WRITE_TEACHER: 1 << 18,
    DELETE_TEACHER: 1 << 19,
    DEVELOPER: 1 << 20,
    READ_SUBSCRIPTION: 1 << 22,
    WRITE_SUBSCRIPTION: 1 << 23,
};

export default Permission;
