import React from "react";
import { useAlert } from "./alertProvider";
import "./alertBanner.css"; // Optional: Add CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_MAPPING } from "../../lib/iconMapping";

const AlertBanner = () => {
    const { alert } = useAlert();

    if (!alert.message) return null;

    return (
        <div className="alert-banner-container">
            <div className="alert-banner">
                <FontAwesomeIcon
                    className={`alert-icon ${alert.type}`}
                    fontSize={"large"}
                    icon={ICON_MAPPING[alert.type]}
                />
                {alert.message}
            </div>
        </div>
    );
};

export default AlertBanner;
