import React, { createContext, useState, useContext } from "react";

const RefreshContext = createContext();

export const useRefreshKey = () => useContext(RefreshContext);

export const RefreshProvider = ({ children }) => {
    const [refreshKey, setRefreshKey] = useState(0);

    const refresh = () => {
        setRefreshKey(refreshKey + 1);
    };

    return (
        <RefreshContext.Provider value={{ refreshKey, refresh }}>
            {children}
        </RefreshContext.Provider>
    );
};
