import React, { useState, useEffect } from "react";

const useFetchWithHeaders = (verb, url, data = null, blob = false) => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const request = {
                    method: verb,
                    headers: {
                        "Content-Type": "application/json",
                        "Cache-Control": "no-cache",
                        Authorization:
                            "Bearer " + localStorage.getItem("auth-token"),
                    },
                };

                if (data) {
                    request.body = JSON.stringify(data);
                }

                const res = await fetch(
                    process.env.REACT_APP_API_URL + url,
                    request
                );
                if (!res.ok) {
                    throw new Error(await res.json());
                }

                const responseData = {
                    status: res.status,
                    data: blob ? await res.blob() : await res.json(),
                };

                setResponse(responseData);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            // Cancel any ongoing fetch requests if component unmounts
        };
    }, [url, data, verb, blob]);

    return { response, loading, error };
};

export default useFetchWithHeaders;
