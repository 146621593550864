import React, { useEffect, useState } from "react";
import fetchWithHeaders from "./fetchWithHeaders";
import Field from "./Field/field";
import SideDrawer from "./SideDrawer/sideDrawer";
import PickTagscomponent from "./PickTagsComponent/pickTagsComponent";
import useFetchWithHeaders from "../hooks/usefetchWithHeaders";
import { useRefreshKey } from "./RefreshProvider/refreshProvider";
import { useAlert } from "./AlertBanner/alertProvider";

const EditUserDrawer = ({ id, isOpen, setIsOpen }) => {
    const [userData, setUserData] = useState({});
    const { refreshKey, refresh } = useRefreshKey();
    const { showAlert } = useAlert();
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/users/${id}?refreshKey=${refreshKey}`
    );

    useEffect(() => {
        if (response) {
            setUserData(response.data);
        }
    }, [response]);

    function updateUser(event) {
        event.preventDefault();
        fetchWithHeaders("PATCH", `/users/${id}`, userData).then(
            ({ status, data, error }) => {
                if (error) {
                    showAlert(
                        "There was an error updating the user: " + error,
                        "error"
                    );
                    return;
                } else {
                    showAlert("Successfully updated user", "success");
                    refresh();
                    setIsOpen(false);
                }
            }
        );
    }

    function handleChange(event) {
        setUserData((previous) => {
            return { ...previous, [event.target.name]: event.target.value };
        });
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return (
            <p className="invalid">There was an error loading this content</p>
        );
    }

    return (
        <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <h2>User Properties</h2>
            <form onSubmit={updateUser} style={{ marginBottom: "2rem" }}>
                <label>
                    Type <input value={userData?.userType} disabled />
                </label>
                <Field
                    label={"First Name"}
                    name={"fname"}
                    value={userData?.fname}
                    onChange={handleChange}
                    required
                />
                <Field
                    label={"Last Name"}
                    name={"lname"}
                    value={userData?.lname}
                    onChange={handleChange}
                    required
                />
                <Field
                    label={"Email"}
                    name={"email"}
                    type="email"
                    value={userData?.email}
                    onChange={handleChange}
                    required
                />

                <PickTagscomponent
                    value={userData?.tags}
                    onChange={(tags) => {
                        setUserData((previous) => {
                            return { ...previous, tags: tags };
                        });
                    }}
                />
                <div className="button-group">
                    <button type="submit">Update</button>
                    <button type="button" onClick={() => setIsOpen(false)}>
                        Cancel
                    </button>
                </div>
            </form>
        </SideDrawer>
    );
};

export default EditUserDrawer;
