import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LogoWithText from "../../components/Logos/logoWithText";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";

const VerifyEmail = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get("token"));
    const navigate = useNavigate();

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/auth/verify-email?token=${token}`
    );

    if (error) {
        return (
            <div className="page centered userSelection">
                <div className="paper">
                    <div>
                        <h2>There was an error verifying your email</h2>
                        <p>
                            Your verification email may be outdated. Please
                            visit your dashboard to resend the email
                        </p>
                        <button onClick={() => navigate(`/login`)}>
                            Back To Login
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    return (
        <div className="page centered userSelection">
            <div className="paper">
                <LogoWithText width="150px" />
                <div>
                    <h2>Your Email Has Been Verified</h2>
                    <button onClick={() => navigate(`/login`)}>
                        Back To Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
