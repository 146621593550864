import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { jwtDecode } from "jwt-decode";
import LogoWithText from "../../components/Logos/logoWithText";
import ListItem from "../../components/ListItem/listItem";
import { formatDate } from "../../lib/format";

const USER_TYPES = ["student", "teacher", "admin"];

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState(null);
    const [users, setUsers] = useState([]);

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/login`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email, password }),
                }
            );

            if (!response.ok) {
                throw new Error(await response.json());
            }
            const body = await response.json();
            if (body?.users?.length > 1) {
                setUsers(body.users);
            } else {
                handleLogin(body?.users[0]?.token);
            }
        } catch (error) {
            setErrorMsg(error.message);
            console.log(error);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("auth-token");
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (currentTime < decodedToken.exp) {
                navigate("/dashboard");
            }
        }
    }, []);

    function handleLogin(token) {
        localStorage.setItem("auth-token", token);
        navigate("/dashboard");
    }

    if (users && users.length > 1) {
        return (
            <div className="page centered">
                <div className="paper">
                    <h2>Multiple Users Found</h2>
                    <ul>
                        {users.map(({ email, createdAt, token }, i) => {
                            return (
                                <ListItem
                                    key={i}
                                    onClick={() => handleLogin(token)}
                                    title={email}
                                    description={
                                        "Created on: " + formatDate(createdAt)
                                    }
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <div className="page centered">
            <div className="paper">
                <LogoWithText width="150px" />
                <div className="login-container">
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <h3 className="invalid">{errorMsg || ""}</h3>
                        <label>
                            Email
                            <input
                                name="email"
                                value={email}
                                onChange={(e) => {
                                    if (errorMsg) {
                                        setErrorMsg(null);
                                    }
                                    setEmail(e.target.value);
                                }}
                                type="email"
                                required
                            />
                        </label>
                        <label>
                            Password
                            <div className="password-container">
                                <input
                                    name="password"
                                    value={password}
                                    onChange={(e) => {
                                        if (errorMsg) {
                                            setErrorMsg(null);
                                        }
                                        setPassword(e.target.value);
                                    }}
                                    type={showPassword ? "text" : "password"}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? "Hide" : "Show"}
                                </button>
                            </div>
                        </label>
                        <button type="submit">Login</button>
                    </form>
                    <p>
                        <a href={`/app/forgot-password`}>Forgot Password?</a>
                    </p>
                </div>
                <p>
                    Don't have an account?{" "}
                    <a href="/app/membership">Register Here</a>
                </p>
            </div>
        </div>
    );
};

export default Login;
