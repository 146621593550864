import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./sideDrawer.css";

const SideDrawer = ({ isOpen, setIsOpen, children }) => {
    return (
        <React.Fragment>
            <div
                className={
                    isOpen
                        ? "side-block-background"
                        : "side-block-background-closed"
                }
                onClick={() => setIsOpen(!isOpen)}
            ></div>

            <div
                className={isOpen ? "side-block side-block-open" : "side-block"}
            >
                <div
                    className="side-block-toggle"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <FontAwesomeIcon
                        icon={isOpen ? faCaretRight : faCaretLeft}
                        className="icon"
                    />
                </div>
                <div className="side-block-scroll-container">{children}</div>
            </div>
        </React.Fragment>
    );
};

export default SideDrawer;
