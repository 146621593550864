import {
    faCheckCircle,
    faExclamationCircle,
    faGraduationCap,
    faMusic,
    faPersonChalkboard,
    faUserTie,
} from "@fortawesome/free-solid-svg-icons";

export const ICON_MAPPING = {
    teacher: faPersonChalkboard,
    student: faGraduationCap,
    admin: faUserTie,
    success: faCheckCircle,
    error: faExclamationCircle,
    piece: faMusic
};
