import React, { useEffect, useState } from "react";
import fetchWithHeaders from "./fetchWithHeaders";
import Field from "./Field/field";
import SideDrawer from "./SideDrawer/sideDrawer";
import Permission from "../lib/permissionMasks";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import PickTagscomponent from "./PickTagsComponent/pickTagsComponent";
import HelpSection from "./HelpSection/helpSection";
import TeacherOptions from "./TeacherOptions/teacherOptions";
import { useAlert } from "./AlertBanner/alertProvider";
import { useRefreshKey } from "./RefreshProvider/refreshProvider";
import PermissionsComponent from "./permissionsComponent";
import PageSummary from "./PageSummary/pageSummary";

const AddUserDrawer = ({ isOpen, setIsOpen }) => {
    const [userData, setUserData] = useState({ tags: [] });
    const [userTypes, setUserTypes] = useState([]);
    const { showAlert } = useAlert();
    const { refresh } = useRefreshKey();
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("auth-token");
        if (!token) {
            navigate("/login");
        }
        const decoded = jwtDecode(token);

        const { permissions } = decoded;
        const uts = [];
        if (
            (Permission.WRITE_STUDENT & permissions) ===
            Permission.WRITE_STUDENT
        ) {
            uts.push("student");
        }
        if (
            (Permission.WRITE_TEACHER & permissions) ===
            Permission.WRITE_TEACHER
        ) {
            uts.push("teacher");
        }
        if ((Permission.WRITE_ADMIN & permissions) === Permission.WRITE_ADMIN) {
            uts.push("admin");
        }

        setUserData((previous) => {
            return { ...previous, userType: uts[0] };
        });
        setUserTypes(uts);
    }, []);

    function addUser(event) {
        event.preventDefault();
        fetchWithHeaders("POST", "/users", userData).then(
            ({ status, data, error }) => {
                if (error) {
                    showAlert(
                        "There was an error creating this user: " + error,
                        "error"
                    );
                    return;
                } else {
                    showAlert("Successfully created user", "success");
                    refresh();
                    setIsOpen(false);
                }
            }
        );
    }

    function handleChange(event) {
        setUserData((previous) => {
            return { ...previous, [event.target.name]: event.target.value };
        });
    }

    return (
        <SideDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <PageSummary>
                <h2>Create New User</h2>
            </PageSummary>
            <form onSubmit={addUser} style={{ marginBottom: "2rem" }}>
                <Field
                    label={"First Name"}
                    name={"fname"}
                    value={userData?.fname}
                    onChange={handleChange}
                    required
                />
                <Field
                    label={"Last Name"}
                    name={"lname"}
                    value={userData?.lname}
                    onChange={handleChange}
                    required
                />
                <Field
                    label={
                        <React.Fragment>
                            Email{" "}
                            <HelpSection
                                title="User Creation"
                                message="The user you create will recieve an email with their account password and instructions for how to use their account"
                            />
                        </React.Fragment>
                    }
                    name={"email"}
                    type="email"
                    value={userData?.email}
                    onChange={handleChange}
                    required
                />
                {userTypes?.length > 1 && (
                    <label>
                        User Type
                        <select
                            name="userType"
                            value={userData?.userType}
                            onChange={handleChange}
                        >
                            {userTypes.map((ut) => {
                                return (
                                    <option key={ut} value={ut}>
                                        {ut}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                )}

                <PermissionsComponent
                    permissions={[
                        Permission.READ_TEACHER,
                        Permission.WRITE_TEACHER,
                    ]}
                >
                    {userData?.userType === "student" && (
                        <TeacherOptions
                            onSelect={(selected) =>
                                setUserData((previous) => {
                                    return { ...previous, teachers: selected };
                                })
                            }
                        />
                    )}
                </PermissionsComponent>

                <PickTagscomponent
                    value={userData?.tags}
                    onChange={(tags) => {
                        setUserData((previous) => {
                            return { ...previous, tags: tags };
                        });
                    }}
                />
                <Field
                    label={
                        <div>
                            Send a custom message{" "}
                            <HelpSection
                                title={"Invite Message"}
                                message={
                                    "Optionally send a custom message to the user in their invitation email"
                                }
                            />
                        </div>
                    }
                    name={"customMessage"}
                    textarea
                    value={userData?.customMessage}
                    onChange={handleChange}
                />
                <button type="submit">Add</button>
            </form>
        </SideDrawer>
    );
};

export default AddUserDrawer;
