import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../../components/pageLayout";
import Table from "../../../components/Table/table";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import { formatDate, formatMS } from "../../../lib/format";
import fetchWithHeaders from "../../../components/fetchWithHeaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faEdit, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PermissionsComponent from "../../../components/permissionsComponent";
import Permission from "../../../lib/permissionMasks";
import EditUserDrawer from "../../../components/editUserDrawer";
import { ICON_MAPPING } from "../../../lib/iconMapping";
import HelpSection from "../../../components/HelpSection/helpSection";
import { useAlert } from "../../../components/AlertBanner/alertProvider";
import { useRefreshKey } from "../../../components/RefreshProvider/refreshProvider";

const UserPage = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const { refreshKey, refresh } = useRefreshKey();

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/users/${userId}&refreshKey=${refreshKey}`
    );
    const navigate = useNavigate();

    useEffect(() => {
        if (response) {
            setUserData(response.data);
        }
    }, [response]);

    const teacherHeadCells = [
        {
            id: "name",
            label: "Name",
            numeric: false,
        },
    ];

    const studentHeadCells = [
        {
            id: "name",
            label: "Name",
            numeric: false,
        },
    ];

    return (
        <PageLayout loading={loading} error={error}>
            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={() => setIsEditing(true)}
            >
                <FontAwesomeIcon
                    color="var(--accent)"
                    fontSize={"x-large"}
                    icon={ICON_MAPPING[userData?.userType] || faUser}
                />
                <h1 style={{ marginBottom: "0" }}>
                    {userData?.fname + " " + userData?.lname}
                </h1>
                <PermissionsComponent
                    permissions={[
                        Permission.WRITE_STUDENT,
                        Permission.WRITE_TEACHER,
                        Permission.WRITE_ADMIN,
                    ]}
                >
                    <FontAwesomeIcon icon={faEdit} color="var(--accent)" />
                </PermissionsComponent>
            </div>
            <PermissionsComponent
                permissions={[
                    Permission.WRITE_STUDENT,
                    Permission.WRITE_TEACHER,
                    Permission.WRITE_ADMIN,
                ]}
            >
                <EditUserDrawer
                    id={userId}
                    isOpen={isEditing}
                    setIsOpen={setIsEditing}
                />
            </PermissionsComponent>
            <div className="tags-container" onClick={() => setIsEditing(true)}>
                {userData?.tags?.map((t) => (
                    <div key={t} className="tag">
                        {t}
                    </div>
                ))}
            </div>
            {userData?.userType === "student" && (
                <React.Fragment>
                    <PermissionsComponent
                        permissions={[Permission.READ_TEACHER]}
                    >
                        <section>
                            <h3>
                                <FontAwesomeIcon
                                    color="var(--accent)"
                                    style={{
                                        marginRight: "0.5rem",
                                    }}
                                    icon={ICON_MAPPING["teacher"]}
                                />
                                Teachers
                            </h3>
                            <p>The teachers assigned to this student</p>
                            <Table
                                rows={userData?.teachers}
                                emptyTableMessage={
                                    "No teachers matched your search"
                                }
                                headCells={teacherHeadCells}
                                bulkActions={[
                                    {
                                        label: (
                                            <HelpSection
                                                title={"Teachers"}
                                                message={
                                                    "Assign this student to a teacher to see them here. Teachers can assign pieces and evaluate student progress!"
                                                }
                                            />
                                        ),
                                        action: () => {},
                                        requiresSelected: false,
                                    },
                                ]}
                                handleClick={(id) =>
                                    navigate(`/dashboard/users/${id}`)
                                }
                            />
                        </section>
                    </PermissionsComponent>
                    <PermissionsComponent
                        permissions={[Permission.READ_ASSIGNMENT]}
                    >
                        <UserAssignments id={userId} />
                    </PermissionsComponent>
                    <StudentPracticeSessions id={userId} />
                </React.Fragment>
            )}

            {userData.userType === "teacher" && (
                <React.Fragment>
                    <section>
                        <h3>
                            <FontAwesomeIcon
                                color="var(--accent)"
                                style={{
                                    marginRight: "0.5rem",
                                }}
                                icon={ICON_MAPPING["student"]}
                            />
                            Students
                        </h3>
                        <p>The students assigned to this teacher</p>
                        <Table
                            rows={userData?.students}
                            emptyTableMessage={
                                "No students matched your search"
                            }
                            headCells={studentHeadCells}
                            bulkActions={[
                                {
                                    label: (
                                        <HelpSection
                                            title={"Students"}
                                            message={
                                                "Assign students to this teacher to see them here. Teachers can assign pieces to students and evaluate student progress!"
                                            }
                                        />
                                    ),
                                    action: () => {},
                                    requiresSelected: false,
                                },
                            ]}
                            handleClick={(id) =>
                                navigate(`/dashboard/users/${id}`)
                            }
                        />
                    </section>
                </React.Fragment>
            )}
        </PageLayout>
    );
};

const UserAssignments = ({ id }) => {
    const headCells = [
        {
            id: "name",
            numeric: false,
            label: "Name",
        },
        {
            id: "composer",
            numeric: false,
            label: "Composer",
        },
        {
            id: "arranger",
            numeric: false,
            label: "Arranger",
        },
        {
            id: "difficulty",
            numeric: true,
            label: "Difficulty",
            responsive: true,
        },
        {
            id: "createdAt",
            numeric: false,
            label: "Date Created",
            formatter: formatDate,
            responsive: true,
        },
    ];

    const [assignments, setAssignments] = useState([]);
    const [assignmentsSelected, setAssignmentsSelected] = useState([]);
    const { showAlert } = useAlert();
    const { refreshKey, refresh } = useRefreshKey();

    function archiveAssignments(selected) {
        if (!assignmentsSelected || assignmentsSelected?.length <= 0) return;
        fetchWithHeaders("PATCH", "/assignments", {
            assignments: assignmentsSelected,
        })
            .then(({ status, data, error }) => {
                if (error) throw new Error(error);
                showAlert("Successfully archived assignments", "success");
                refresh();
            })
            .catch((error) => {
                console.log(error);
                showAlert(
                    "There was an error archiving the assignments: " + error,
                    "error"
                );
            });
    }

    const bulkActions = [
        {
            label: "Archive",
            action: archiveAssignments,
            requiresSelected: true,
        },
        {
            label: (
                <HelpSection
                    title="Assignments"
                    message="Assign a piece to this student so they can start practicing! Once assigned, students can generate practice plans."
                />
            ),
            action: () => {},
            requiresSelected: false,
        },
    ];

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/assignments?studentId=${id}&refreshKey=${refreshKey}`
    );

    useEffect(() => {
        if (response) {
            setAssignments(response.data);
        }
    }, [response]);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    if (error) {
        return (
            <section>
                <p>There was an error loading this content</p>
            </section>
        );
    }
    return (
        <section>
            <h3>
                <FontAwesomeIcon
                    icon={faClipboard}
                    color="var(--accent)"
                    style={{ marginRight: "0.5rem" }}
                />
                Assignments
            </h3>
            <p>Assignments for this student</p>
            <Table
                headCells={headCells}
                orderByDefault={headCells[0].id}
                rows={assignments}
                bulkActions={bulkActions}
                emptyTableMessage={"No Assignments"}
                onSelect={(selected) => setAssignmentsSelected(selected)}
            />
        </section>
    );
};

const StudentPracticeSessions = ({ id }) => {
    const headCells = [
        {
            id: "date",
            numeric: false,
            label: "Date",
            formatter: formatDate,
        },
        {
            id: "totalTime",
            numeric: false,
            label: "Practice Time",
            formatter: formatMS,
        },
    ];

    const [practices, setPractices] = useState([]);
    const [practicesSelected, setPracticesSelected] = useState([]);

    const bulkActions = [];

    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        `/practices?studentId=${id}`
    );

    useEffect(() => {
        if (response) {
            setPractices(response.data);
        }
    }, [response]);

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }
    return (
        <section>
            <h3>Practice Sessions</h3>
            <p>Practice sessions for this student</p>
            <Table
                headCells={headCells}
                orderByDefault={headCells[0].id}
                rows={practices}
                bulkActions={bulkActions}
                emptyTableMessage={"No Practice Sessions"}
                onSelect={(selected) => setPracticesSelected(selected)}
            />
        </section>
    );
};

export default UserPage;
