export function formatMS(rawTime, pretty = false) {
    const hours = Math.floor(rawTime / 3600000);
    const minutes = Math.floor((rawTime / 60000) % 60);
    const seconds = Math.floor((rawTime % 60000) / 1000).toFixed(0);
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedSec = seconds < 10 ? "0" + seconds : seconds;
    const formattedMin = minutes < 10 ? "0" + minutes : minutes;

    if (!pretty) {
        return formattedHours + ":" + formattedMin + ":" + formattedSec;
    }

    let toReturn = "";

    if (hours > 0) {
        if (hours < 10) {
            toReturn += hours;
        } else {
            toReturn += formattedHours;
        }
        toReturn += " hr";
        if (hours > 1) {
            toReturn += "s ";
        }
    }
    if (minutes > 0) {
        if (hours > 0) toReturn += " ";
        if (minutes < 10) {
            toReturn += minutes;
        } else {
            toReturn += formattedMin;
        }
        toReturn += " minute";
        if (minutes > 1) {
            toReturn += "s";
        }
    }
    if (seconds > 0) {
        if (minutes > 0 || hours > 0) toReturn += " ";
        if (seconds < 10) {
            toReturn += seconds;
        } else {
            toReturn += formattedSec;
        }
        toReturn += " second";
        if (seconds > 1) {
            toReturn += "s";
        }
    }
    return toReturn;
}

export function formatDate(date) {
    let newDate = new Date(date);

    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return month + "/" + day + "/" + year;
}

export function formatSize(sizeBytes) {
    let finalSize = sizeBytes;
    let unit = "B";
    if (sizeBytes > 1_000) {
        finalSize /= 1_000;
        unit = "KB";
    }
    if (sizeBytes > 1_000_000) {
        finalSize /= 1_000;
        unit = "MB";
    }
    if (sizeBytes > 1_000_000_000) {
        finalSize /= 1_000;
        unit = "GB";
    }
    let accuracy = 2;
    if (finalSize > 100) {
        accuracy = 0;
    }
    return Math.fround(finalSize).toFixed(accuracy) + unit;
}
