import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LogoWithText from "../../components/Logos/logoWithText";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import fetchWithHeaders from "../../components/fetchWithHeaders";
import Field from "../../components/Field/field";

const StudentInvite = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get("token"));
    const [studentInfo, setStudentInfo] = useState();
    const [success, setSuccess] = useState(false);
    const [passError, setPassError] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleAcceptInvite(e) {
        e.preventDefault();
        if (studentInfo.password !== studentInfo.confirmPass) {
            setPassError(true);
            return;
        }
        fetch(process.env.REACT_APP_API_URL + `/students/accept-invite`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(studentInfo),
        })
            .then((res) => res.json())
            .then((data) => {
                setSuccess(true);
            })
            .catch((error) => {
                alert(error);
                console.log(error);
            });
    }

    function handleChange(e) {
        setStudentInfo((previous) => {
            return { ...previous, [e.target.name]: e.target.value };
        });
    }

    useEffect(() => {
        async function handleInviteData() {
            try {
                setLoading(true);
                const res = await fetch(
                    process.env.REACT_APP_API_URL + `/students/student-invite`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                    }
                );
                if (!res.ok) throw new Error("Bad token");
                const data = await res.json();
                setStudentInfo(data);
            } catch (error) {
                setError(true);
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        handleInviteData();
    }, []);

    if (error) {
        return (
            <div className="page centered userSelection">
                <div className="paper">
                    <div>
                        <h2>There was an error with this link</h2>
                        <p>
                            The link in your email may be outdated. Please ask
                            your teacher to resend the email
                        </p>
                        <button onClick={() => navigate(`/login`)}>
                            Back To Login
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="loading-paper">
                <div className="loading-anim"></div>
            </div>
        );
    }

    if (success) {
        return (
            <div className="page centered userSelection">
                <div className="paper">
                    <h2>Successfully Accepted Invitation</h2>
                    <a href="/app/login?userType=student">
                        <p>Click here to login</p>
                    </a>
                </div>
            </div>
        );
    }

    if (studentInfo?.registered) {
        return (
            <div className="page centered">
                <div className="paper">
                    <LogoWithText width="150px" />
                    <h2>Thanks for using Practice Pro!</h2>
                    <p>A teacher has invited you to their studio!</p>
                    <h1>{studentInfo.studioName}</h1>
                    <h3>{studentInfo.teacherName}</h3>
                    <button onClick={handleAcceptInvite}>
                        Accept Invitation
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="page centered">
            <div className="paper">
                <LogoWithText width="150px" />
                <form onSubmit={handleAcceptInvite}>
                    <h2>Welcome to Practice Pro!</h2>
                    <p>
                        Please fill out the information below to join your
                        teacher's studio and start practicing!
                    </p>
                    <Field
                        label={"First Name"}
                        name={"fname"}
                        value={studentInfo?.fname}
                        onChange={handleChange}
                    />
                    <Field
                        label={"Last Name"}
                        name={"lname"}
                        value={studentInfo?.lname}
                        onChange={handleChange}
                    />
                    <p>Create a password for your account</p>
                    {passError && (
                        <p className="invalid">The passwords do not match</p>
                    )}
                    <Field
                        label={"Password"}
                        name={"password"}
                        type="password"
                        value={studentInfo?.password}
                        onChange={(e) => {
                            setPassError(false);
                            handleChange(e);
                        }}
                    />
                    <Field
                        label={"Confirm Password"}
                        name={"confirmPass"}
                        type="password"
                        value={studentInfo?.confirmPass}
                        onChange={(e) => {
                            setPassError(false);
                            handleChange(e);
                        }}
                    />
                    <button type="submit">Finish Registration</button>
                </form>
            </div>
        </div>
    );
};

export default StudentInvite;
