import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../hooks/usefetchWithHeaders";
import fetchWithHeaders from "../../components/fetchWithHeaders";
import Popup from "../Popup/popup";
import { useAlert } from "../AlertBanner/alertProvider";
import "./updateHandler.css";

const UPDATE_STORAGE_KEY = "has-had-update";

const UpdateHandler = () => {
    const [newUpdates, setNewUpdates] = useState([]);
    const { response, loading, error } = useFetchWithHeaders("GET", "/updates");
    const [isOpen, setIsOpen] = useState(false);
    const [activeSection, setActiveSection] = useState(0);
    const [sections, setSections] = useState([]);
    const { showAlert } = useAlert();

    useEffect(() => {
        if (response) {
            const { data } = response;
            if (!data || data.length < 1) {
                return;
            }
            setNewUpdates(data);
            setSections(JSON.parse(data[0]?.content).sections);
            const hasHadUpdate = sessionStorage.getItem(UPDATE_STORAGE_KEY);
            if (hasHadUpdate !== "true" && data?.length > 0) {
                setIsOpen(true);
            }
        }
    }, [response]);

    async function handleUserSeenUpdate() {
        try {
            const { status, data, error } = await fetchWithHeaders(
                "POST",
                `/updates/${newUpdates[0].id}`
            );
            if (error) throw new Error(error);
            setIsOpen(false);
            sessionStorage.setItem(UPDATE_STORAGE_KEY, true);
        } catch (error) {
            console.log(error);
            showAlert("There was an error: " + error, "error");
        }
    }

    if (loading) {
        return;
    }

    if (error) {
        return;
    }

    return (
        <Popup open={isOpen} setIsOpen={setIsOpen}>
            <div className="update-container">
                {sections?.map(({ title, content }, i) => {
                    if (i !== activeSection) return;
                    return (
                        <div className="update-section" key={i}>
                            <h2 className="update-title">{title}</h2>
                            <div className="update-content">
                                {content.map ? (
                                    content.map(({ subheading, text }, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="update-subsection"
                                            >
                                                <h3 className="update-subheading">
                                                    {subheading}
                                                </h3>
                                                <p className="update-subsection-text">
                                                    {text}
                                                </p>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p>{content}</p>
                                )}
                            </div>
                            <button
                                onClick={() => {
                                    if (activeSection === sections.length - 1) {
                                        handleUserSeenUpdate();
                                    } else {
                                        setActiveSection(activeSection + 1);
                                    }
                                }}
                            >
                                {activeSection === sections.length - 1
                                    ? "Got it!"
                                    : "Next"}
                            </button>
                        </div>
                    );
                })}
                <div className="section-navigator">
                    {sections.map((s, i) => {
                        const active = i === activeSection;
                        return (
                            <div
                                key={i}
                                className={
                                    active
                                        ? "section-dot section-dot-active"
                                        : "section-dot"
                                }
                                onClick={() => setActiveSection(i)}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </Popup>
    );
};

export default UpdateHandler;
