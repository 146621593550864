import React, { useEffect, useState } from "react";
import useFetchWithHeaders from "../../../hooks/usefetchWithHeaders";
import { formatMS } from "../../../lib/format";

const PracticeInsights = () => {
    const { response, loading, error } = useFetchWithHeaders(
        "GET",
        "/insights/practices"
    );
    const [insights, setInsights] = useState({});

    useEffect(() => {
        if (response) {
            setInsights(response.data);
        }
    }, [response]);

    if (loading) {
        return (
            <div className="insight-grid grid-3">
                <div className="loading-paper">
                    <div className="loading-anim"></div>
                </div>
                <div className="loading-paper">
                    <div className="loading-anim"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="insight-grid grid-3">
            <div className="paper">
                <h3>Average Practice Duration</h3>
                <h1>{formatMS(insights?.averageDuration, true)}</h1>
            </div>
            <div className="paper">
                <h3>Total Practice Duration</h3>
                <h1>{formatMS(insights?.totalDuration, true)}</h1>
            </div>
            <div className="paper">
                <h3>Total Practice Sessions</h3>
                <h1>{insights?.sessionCount}</h1>
            </div>
        </div>
    );
};

export default PracticeInsights;
