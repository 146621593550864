import React, { useState } from "react";
import PageLayout from "../../../components/pageLayout";
import Field from "../../../components/Field/field";

import "./support.css";
import fetchWithHeaders from "../../../components/fetchWithHeaders";

const SupportPage = () => {
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchWithHeaders("POST", "/support-email", formData)
            .then(({ status, data, error }) => {
                if (error) throw new Error(error);
                setSubmitted(true);
            })
            .catch((error) => {
                console.log(error);
                alert("There was an error submitting the form");
            });
    };

    if (submitted) {
        return (
            <PageLayout disableSideComponent>
                <div className="submission-message">
                    <h1>Thanks For Contacting Us!</h1>
                    <h4>
                        Hang tight! Our support team will assist you soon. We
                        will reach out to you via your account's email address.
                    </h4>
                </div>
            </PageLayout>
        );
    }

    return (
        <PageLayout disableSideComponent>
            <div className="support-form">
                <h1>PracticePro</h1>
                <h2>Support</h2>
                <form onSubmit={handleSubmit}>
                    <Field
                        rows={6}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        textarea
                        label="How can we help? Please be specific about the problem you are facing so we can assist you swiftly"
                        required
                    />
                    <div className="button-group">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </PageLayout>
    );
};

export default SupportPage;
